import React, { useEffect, useState } from 'react'
import SideBar from './SideBar'
import moment from 'moment';
import axiosClient from '../axios';
import { useNavigate } from 'react-router-dom';

import loader from "../assets/images/loader.gif";
import { useParams } from 'react-router-dom';
import Slide from '@mui/material/Slide';
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function UserTransaction() {
    const { id } = useParams();
    const [open, setOpen] = useState(false)
    const [openChangeStatus, setOpenChangeStatus] = useState(false)
    const [scractLoad, setScractLoad] = useState(false)
    const [blockOpen, setBlockOpen] = useState(false)
    const [stop_purchaseOpen, setStop_purchaseOpen] = useState(false)
    const [user, setUser] = useState([]);
    const [allProvider, setAllProvider] = useState([]);
    const [provider, setProvider] = useState();
    const [isBlocked, setIsBlocked] = useState(false);
    const [blockReason, SetBlockReason] = useState("")
    const [isPurchase, setisPurchase] = useState(false);
    const [show, setShow] = useState(false)
    const [option, setOptions] = useState('')
    const [status, setStatus] = useState(0)
    const [isStatus, setIsStatus] = useState(false)
    const [spin_wheel_earning, setSpin_Wheel_Earning] = useState(0)
    const [brainVellyLevel, setBrainVellyLevel] = useState(0)
    const [scratchCardInfo, setScratchCardInfo] = useState({
        totalCardcount: 0,
        totalEarning: 0,
        loginScratchCard: 0,
        refrelScratchCard: 0
    })
    const [isScratchGive, setIsScratchGive] = useState(false)
    const [noOFScratchCard, setNoOFScratchCard] = useState(0)
    const [isSpinWheelGive, setIsSpinWheelGive] = useState(false)
    const [noOFSpinWheel, setNoOFSpinWheel] = useState(0)
    const [rowPerPage, setRowPerPage] = useState(50);
    const [currentPage, setCurrentPage] = useState(0);
    const [totalPage, setTotalPage] = useState(10)
    const [data, setData] = useState([])
    const [transfer, setTransfer] = useState({
        user_id: id, coins: 0, msg: ""
    })
    const [offerInProgressData, setOfferInProgressData] = useState([])
    const [coinTransfer, setCoinTransfer] = useState(false)
    const [loading, setLoading] = useState(true)
    const navigate = useNavigate();
    const [referThroughUserData, setReferThroughUserData] = useState([])
    const [TransData, setTransData] = useState({})
    const [userReferStat, setUserReferstat] = useState({})
    const [isLoading, setIsLoading] = useState(false)

    const handleDelete = () => {
        setOpen(false);
        setTransfer({
            user_id: id, coins: 0,
        })
    };
    const handleBlockDelete = () => {
        setBlockOpen(false);
        setIsScratchGive(false)
        setIsSpinWheelGive(false)
        setStop_purchaseOpen(false)
        SetBlockReason("")
        setOpenChangeStatus(false)
    }
    const handleBlock = (e) => {
        if (e === 1) {
            if (!blockReason) {
                alert("Please Enter Block Reason")
                return
            }
        }
        console.log('e', e, isBlocked, isPurchase, typeof e)
        const formData = {
            userId: user._id,
            block: e === 1 ? !isBlocked : isBlocked,
            purchaseValue: e === 2 ? !isPurchase : isPurchase,
            blockReason: blockReason
        }
        axiosClient
            .put(`/blockUser`, formData)
            .then((res) => {
                if (res.data.status == false) {
                    alert(res.data.message)
                }
                getUsers();
                setBlockOpen(false);
                setStop_purchaseOpen(false)
                SetBlockReason("")

            })
            .catch((error) =>
                console.log(error)
            );
    }

    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }

    const getOfferInProgess = () => {
        setLoading(false)

        axiosClient
            .get(`/getOfferInProgerss/${id}?page=${currentPage}&limit=${rowPerPage}&status=${status}`)
            .then((res) => {
                setLoading(true)

                setOfferInProgressData(res.data.offerInProgress)
            })
            .catch((error) => console.log(error))
    }


    const scratchCardsHandle = () => {
        axiosClient.get(`/getScratchCard/${id}`)
            .then((res) => {
                setScratchCardInfo({ totalEarning: res?.data?.totalEarning, totalCardcount: res?.data?.totalCardcount, loginScratchCard: res?.data?.loginScratchCard, refrelScratchCard: res?.data?.refrelScratchCard })
            })
            .catch((error) => console.log("error", error))
    }

    const getUsers = () => {
        setLoading(false)

        axiosClient
            .get(`/admin/getDetailUser/${id}?page=${currentPage}&limit=${rowPerPage}`)
            .then((res) => {
                setLoading(true)
                setUser(res.data?.response?.userInfo)
                setSpin_Wheel_Earning(res.data?.response?.spin_wheel_amount)
                setBrainVellyLevel(res.data?.response?.brain_velly_level)
                res.data?.response?.userInfo.is_blocked ? setIsBlocked(true) : setIsBlocked(false)
                res.data?.response?.userInfo.stop_purchase ? setisPurchase(true) : setisPurchase(false)
                setData(res.data?.response?.userTransaction?.totalData)
                setTotalPage(res?.data?.response?.userTransaction?.totalCount?.count)
            })
            .catch((error) =>
                console.log(error)
            );
    }

    const referThroughUser = () => {
        setLoading(false)

        axiosClient
            .get(`/admin/getReferThroughUsersDetail/${id}?page=${currentPage}&limit=${rowPerPage}`)
            .then((res) => {
                setLoading(true)
                console.log("response form refer through", res)

                setData(res.data?.response?.totalData)
                setReferThroughUserData(res.data?.response?.totalData)
                setTotalPage(res.data?.response?.totalData?.length)


            })
            .catch((error) =>
                console.log(error)
            );
    }

    const getLatestTransaction = (partner) => {
        setLoading(false)
        axiosClient
            .get(`/admin/getDetailUser/${id}?page=${currentPage}&limit=${rowPerPage}&partner=${partner}`)
            .then((res) => {
                setLoading(true)
                setUser(res.data?.response?.userInfo)
                setData(res.data?.response?.userTransaction?.totalData)
                setTotalPage(res?.data?.response?.userTransaction?.totalCount[0]?.count)
            })
            .catch((error) =>
                console.log(error)
            );
    }
    const adminAmount = (e) => {
        setCoinTransfer(true)
        let data = transfer

        data.type = Number(e)
        axiosClient
            .put(`/transfertoUser`, data)
            .then((res) => {
                alert(res.data.message)
                getUsers();
                handleDelete()
            })
            .catch((error) =>
                console.log(error)
            )
            .finally(() => setCoinTransfer(false))

    }

    const giveScratch = () => {
        if (noOFScratchCard > 5) {
            alert('Given Scratch Should Not be Grater Then 5')
            return
        }
        setScractLoad(true)
        setIsScratchGive(false)
        axiosClient
            .get(`/giveScratch/${id}?scratchCardNumber=${noOFScratchCard}`)
            .then((res) => {
                if (res.data.status === true) {
                    alert("scratch card given successfully")
                    setNoOFScratchCard(0)
                }
                else {
                    alert(res.data.message)
                }
            })
            .catch((error) => console.log(error)).finally(() => setScractLoad(false))
    }

    const giveSpinWheel = () => {
        if (noOFSpinWheel > 10) {
            return alert('Given SpinWheel Should Not be Grater Then 10')
        }
        setScractLoad(true)
        axiosClient
            .get(`/giveSpinWheel/${id}?spinWheelNumber=${noOFSpinWheel}`)
            .then((res) => {
                if (res.data.status === true) {
                    alert("spin wheel given successfully")
                    setNoOFSpinWheel(0)
                    setIsSpinWheelGive(false)
                } else {
                    alert(res.data.message)
                }
            })
            .catch((error) => console.log(error)).finally(() => setScractLoad(false))
    }

    const handleStatusTypeChange = () => {
        setOpenChangeStatus(false)
        axiosClient.put(`upDateStatusType`, TransData)
            .then((res) => {
                alert('status changed successfully')
                getUsers()
            })
            .catch((error) => console.log(error))
    }


    const handleUserReferLink = () => {
        setIsLoading(true)
        axiosClient.get(`/admin/referral_details_by_user?userId=${id}`)
            .then((res) => {
                console.log("res==>", res)
                setUserReferstat(res?.data?.response[0])
            })
            .catch((error) => console.log("error"))
            .finally(() => setIsLoading(false))
    }



    useEffect(() => {
        if (status == 4) {
            referThroughUser()
        }
        else {
            getUsers()
            scratchCardsHandle()
        }
    }, [currentPage, rowPerPage, scractLoad])

    useEffect(() => {
        getPartner();
    }, []);


    useEffect(() => {
        if (status === 1) {
            getOfferInProgess()
        } if (status === 2) {
            getUsers()
        }
    }, [status])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"users"} />
            <div className="screen">
                <h2 className='screenHeading catHead'>User Details</h2>
                <div className='user_trans_mainC_container'>
                    <div className="user-detal-section">
                        <div className='dblock-1st-div'>
                            <p className='catPara'><span>Name</span> : {user?.name}</p>
                            <p className='catPara'><span>Email</span> : {user?.emailId}</p>
                            <p className='catPara'><span>Number</span> : {user?.phoneNumber}</p>
                            <p className='catPara'><span>Is Blocked</span> : {user?.is_blocked ? "Blocked" : "Not Blocked"}</p>
                            <p className='catPara'><span>Wallet</span> : {user?.wallet?.toFixed(2)}</p>
                            <p className='catPara'><span>Spin Wheel Received</span> : {user?.spin_wheel?.received}</p>
                            <p className='catPara'><span>Spin Wheel Used</span> : {user?.spin_wheel?.used}</p>
                            <p className='catPara'><span>refer_code</span> : {user?.refer_code}</p>
                            <p className='catPara'><span>brainVellyLevel</span> : {brainVellyLevel ? brainVellyLevel : 0}</p>
                            {user?.is_blocked && <p className='catPara'><span>User Blocked Reason</span> : {user?.block_info?.block_reason}</p>}
                        </div>

                        <div className='dblock-2st-div'>
                            <p className='catPara'><span>Total Scratch Card</span> : {scratchCardInfo?.totalCardcount}</p>
                            <p className='catPara'><span>Login Scratch Card</span> : {scratchCardInfo?.loginScratchCard}</p>
                            <p className='catPara'><span>Referal Scratch Card</span> : {scratchCardInfo?.refrelScratchCard}</p>
                            <p className='catPara'><span>Earning From ScratchCard</span> : {scratchCardInfo?.totalEarning}</p>
                            <p className='catPara'><span>daily_streak_count</span> : {user?.daily_streak_count}</p>
                            <p className='catPara'><span>Past Wallet</span> : {user?.pastWallet?.toFixed(2)}</p>
                            <p className='catPara'><span>SpinWheelEarning</span> : {spin_wheel_earning}</p>
                            <p className='catPara'><span>Offer Pending-Earning</span> : {user?.pending_earning?.offers?.toFixed(2)}</p>
                            <p className='catPara'><span>Refers Pendning-Earning</span> : {user?.pending_earning?.refers.toFixed(2)}</p>
                        </div>

                        <div className='dblock-1st-div'>
                            <p className='user-refer-heading'>Download by Refer link</p>

                            {isLoading ?
                                <div className='refer-stat-loader'>
                                    <img src={loader} alt='loader' />
                                </div>
                                :
                                <div className="user-refer-stat">
                                    <p>Total Click {userReferStat?.totalClick >= 0 ? userReferStat?.totalClick : "-"}</p>
                                    <p>Total Download  {userReferStat?.totalDownload >= 0 ? userReferStat?.totalDownload : "-"}</p>
                                    <p>Total Register {userReferStat?.totalRegister >= 0 ? userReferStat?.totalRegister : "-"}</p>
                                    <button onClick={() => handleUserReferLink()}>Get</button>
                                </div>
                            }
                        </div>
                    </div>

                    <div className="user-button-section">

                        <div className='dblock-3st-div'>
                            <div className='user-popup-sec'>
                                <div className='user_trans_b' onClick={() => { !scractLoad ? setIsScratchGive(true) : setIsScratchGive(false) }}>Give scratch card</div>
                                <div className='user_trans_b' onClick={() => setOpen(true)}>Amount By Admin</div>
                                <div className='user_trans_b' onClick={() => { setIsSpinWheelGive(true) }}>Give Spin Wheel</div>
                                <div className='user_trans_b' onClick={() => setStop_purchaseOpen(true)}>{user?.stop_purchase ? "purchase" : "stop_purchase"}</div>
                                <div className='user_trans_b' onClick={() => setBlockOpen(true)}>{user?.is_blocked ? "unblock user" : "block user"}</div>
                            </div>
                            <div className='user-transaction-sec'>
                                <div className='user_trans_b' onClick={() => { referThroughUser(); setStatus(4) }}>ReferThrough</div>
                                <div className='user_trans_b' onClick={() => { navigate(`/userLogs/${id}`) }}>User Logs</div>
                                <div style={{ position: 'relative' }} className='user_trans_b' onClick={() => { setShow(!show) }} >
                                    <div >
                                        {option === '' ? "Transactions" : option}
                                    </div>

                                    <div className={show ? `show-drop` : `hide-drop`}>
                                        <span onClick={(e) => { setIsStatus(false); setOptions(e.target.innerHTML); }}>Transaction </span>
                                        <span onClick={(e) => { setIsStatus(true); setOptions(e.target.innerHTML); }}>offer In Progress</span>
                                    </div>
                                </div>
                                <div className='user_trans_b' onClick={() => { isStatus ? setStatus(1) : setStatus(2) }}>Submit</div>
                            </div>
                        </div>
                    </div>

                </div>


                {
                    loading ? <>
                        <div className='profileInfoBox table '>
                            <div className='flex'>
                                <h5>{status == 4 ? "Refer User" : "Transaction Details"}</h5>
                                <div className='editCover'>
                                    <Autocomplete
                                        disablePortal
                                        value={provider?.name}
                                        onChange={(event, newValue) => {
                                            setProvider({
                                                id: newValue?._id,
                                                name: newValue?.name
                                            });
                                            getLatestTransaction(newValue?._id)
                                        }}
                                        options={allProvider}
                                        getOptionLabel={option => option?.name || option}

                                        renderInput={(params) => (
                                            <TextField
                                                {...params}
                                                label="Provider"
                                            />
                                        )}
                                    />
                                </div>
                            </div>
                            <div style={{ width: '100%' }} className="container app-body">
                                <div className="tabel-scrol">

                                    <table id="users">
                                        {
                                            data?.length > 0 && (status == 0 || status == 2) ? <>
                                                <tr>
                                                    <th style={{ width: '50px' }}>S.NO.</th>
                                                    <th style={{ width: '80px' }}>Offer Name</th>
                                                    <th style={{ width: '150px' }}>Txnd Id</th>
                                                    <th style={{ width: '130px' }}>Provider</th>
                                                    <th style={{ width: '100px' }}>Amount</th>
                                                    <th style={{ width: '200px' }}>Message</th>
                                                    <th style={{ width: '100px' }}>Earning Type</th>
                                                    <th style={{ width: '70px' }}>statusType</th>
                                                    <th style={{ width: '200px' }}>Time</th>
                                                    <th style={{ width: '200px' }}>Change Status</th>
                                                </tr>
                                                {data?.map((val, index) => {
                                                    return (
                                                        <React.Fragment key={index}>
                                                            <tr>
                                                                <td>{(rowPerPage * currentPage) + (index + 1)}</td>
                                                                <td >{val?.offerId?.name ? val?.offerId?.name : "NULL"}</td>
                                                                <td>{val?.transactionId}</td>
                                                                <td>{val?.partnerId?.name ? val?.partnerId?.name : val?.by_admin === true ? "Admin" : val?.statusType == 2 ? "Topupx" : "NA"}</td>
                                                                <td>{val?.amount}</td>
                                                                <td>{val?.admin_msg ? val?.admin_msg : "NA"}</td>
                                                                <td>{val?.earning_type == 1 ? "spinWheel" : val?.earning_type == 2 ? "ScratchCard" : val?.earning_type == 3 ? "singup" : val?.earning_type == 4 ? "Weekly" : val?.earning_type === 5 ? "SC Referal" : val?.earning_type === 6 ? "LeaderBoard Prize" : val?.earning_type === 7 ? "Affiliate Earning" : val?.earning_type === 8 ? "OfferWall Unlock" : val?.earning_type === 9 ? "Giveaways" : "null"}</td>
                                                                <td>{val?.statusType === 1 ? "Credited" : val?.statusType === 2 ? "Debited" : val?.statusType === 3 ? "Pending" : val?.statusType === 4 ? "Invalid" : "Null"}</td>
                                                                <td>{moment.utc(val?.time).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                                {/* {(val?.statusType === 1 || val?.statusType === 3) ? <td>{<p className='TransactionBox' onClick={() => { setOpenChangeStatus(true); setTransData({ transId: val?._id }) }}>Change Status</p>}</td> : val?.statusType === 2 ? <td>Not Applicable</td> : <td>{<p className='TransactionBox' style={{ "background": "grey" }} disabled >Change Status</p>}</td>} */}
                                                                {(val?.earning_type === 0 && val?.statusType != 4 && val?.statusType != 2) ? <td>{<p className='TransactionBox' onClick={() => { setOpenChangeStatus(true); setTransData({ transId: val?._id }) }}>Change Status</p>}</td> : <td>{<p className='TransactionBox' style={{ "background": "grey" }} disabled >Change Status</p>}</td>}

                                                            </tr>
                                                        </React.Fragment>)
                                                })
                                                }

                                            </>
                                                : offerInProgressData?.length > 0 && (status == 1) ?
                                                    <>
                                                        <tr>
                                                            <th style={{ width: '50px' }}>S.NO.</th>
                                                            <th style={{ width: '80px' }}>Offer Name</th>
                                                            <th style={{ width: '100px' }}>Txnd Id</th>
                                                            <th style={{ width: '100px' }}>Offer Id</th>
                                                            <th style={{ width: '100px' }}>status</th>
                                                        </tr>
                                                        {offerInProgressData?.map((val, key) => {
                                                            return <tr>
                                                                <td>{(rowPerPage * currentPage) + (key + 1)}</td>
                                                                <td >{val.offerName}</td>
                                                                <td>{val.transactionId}</td>
                                                                <td>{val.offerId && typeof val.offerId === 'object' ? val.offerId._id : val.offerId}</td>
                                                                <td>{val.status}</td>
                                                            </tr>
                                                        })}
                                                    </>
                                                    :
                                                    referThroughUserData?.length > 0 && (status == 4) ? <>
                                                        <tr>
                                                            <th style={{ width: '50px' }}>S.NO.</th>
                                                            <th style={{ width: '100px' }}>UserName</th>
                                                            <th style={{ width: '230px' }}>Email Id </th>
                                                            <th style={{ width: '130px' }}>Phone Number</th>
                                                            <th style={{ width: '130px' }}>ReferCode</th>
                                                            {/* <th style={{ width: '100px' }}>Refer through</th> */}
                                                            <th style={{ width: '70px' }}>Phone updated</th>
                                                            <th style={{ width: '100px' }}>Earnings</th>
                                                            <th style={{ width: '230px' }}>Last Login At</th>
                                                            <th style={{ width: '230px' }}>Registered On</th>
                                                            <th style={{ width: '70px' }}>Wallet</th>
                                                            <th style={{ width: '90px' }}>Tasks Performed</th>
                                                            <th style={{ width: '70px' }}>Past Wallet</th>
                                                            <th style={{ width: '70px' }}>Country Code</th>
                                                            <th style={{ width: '70px' }}>Is Blocked</th>
                                                        </tr>
                                                        {data?.map((val, key) => (
                                                            <tr key={key}>
                                                                <td>{(rowPerPage * currentPage) + (key + 1)}</td>
                                                                <td onClick={() => navigate(`/users/transaction/${val._id}`)}>{val?.name}</td>
                                                                <td>{val?.emailId}</td>
                                                                <td>{val?.phoneNumber}</td>
                                                                <td>{val?.refer_code}</td>

                                                                <td>{val?.is_phone}</td>
                                                                <td>{val?.earnings?.toFixed(2)}</td>
                                                                <td>{moment.utc(val?.last_login_at).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                                <td>{moment.utc(val?.registered_on).local().format("MMM Do YY, h:mm:ss a")}</td>
                                                                <td>{val?.wallet?.toFixed(2)}</td>
                                                                <td>{val?.tasksPerformed}</td>
                                                                <td>{val?.pastWallet}</td>
                                                                <td>{val?.countryCode}</td>
                                                                <td onClick={() => { if (val.is_blocked) { setIsBlocked(true) } else { setIsBlocked(false) }; setOpen(true); setUser(val._id) }}>{val.is_blocked ? "Blocked" : "UnBlock"}</td>
                                                            </tr>
                                                        ))}
                                                    </> :
                                                        <h3>No Transaction Found </h3>
                                        }
                                    </table>
                                </div>
                            </div>
                            {/* pagination set  */}
                            <div className=' userTablePagination'>
                                <label>Rows per page:</label>
                                <select value={rowPerPage} onChange={(e) => { setRowPerPage(e.target.value); setCurrentPage(0) }}>
                                    <option value={10}>10</option>
                                    <option value={50}>50</option>
                                    <option value={100}>100</option>
                                    <option value={500}>500</option>
                                    <option value={1000}>1000</option>
                                    <option value={1500}>1500</option>
                                </select>
                                <span className='currentPagespan'> Current Page {currentPage + 1}/{Math.ceil(totalPage / rowPerPage)} </span>

                                {currentPage !== 0 &&
                                    (<button className='paginationButton' onClick={() => setCurrentPage(currentPage - 1)} disabled={currentPage === 0}>
                                        Previous
                                    </button>)
                                }

                                {
                                    Math.ceil(totalPage / rowPerPage) !== 1 && Math.ceil(totalPage / rowPerPage) != currentPage + 1 &&
                                    (<button className='paginationButton'
                                        onClick={() => setCurrentPage(currentPage + 1)}
                                        disabled={currentPage + 1 >= totalPage / rowPerPage}
                                    >
                                        Next
                                    </button>)
                                }
                            </div>
                        </div>
                    </>
                        : <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                }

            </div>
            {
                <Dialog
                    open={open}
                    onClose={() => handleDelete()}
                    TransitionComponent={Transition}
                    className="dialogs"
                >
                    <div className='categoryEditBox'>
                        <h3>Transfer By Admin</h3>
                        <div className="pRelative w300">
                            <p>
                                Coins
                            </p>
                            <input
                                name="coins"
                                placeholder={'Coins'}
                                onChange={(e) => {
                                    setTransfer({ ...transfer, coins: e.target.value });
                                }}
                                type="number"
                                value={transfer.coins}
                                className="inputTag"
                            />
                            <input
                                name="msg"
                                placeholder={'Message'}
                                onChange={(e) => {
                                    setTransfer({ ...transfer, msg: e.target.value });
                                }}
                                type="text"
                                value={transfer.msg}
                                className="inputTag m10"
                            />
                        </div>
                        <div className='dFlex'>
                            <button className='editButton w45' disabled={coinTransfer} onClick={() => adminAmount(1)}>Deposit</button>
                            <button className='editButton w45' disabled={coinTransfer} onClick={() => adminAmount(2)} >WithDraw</button>
                        </div>
                    </div>
                </Dialog>
            }

            <Dialog
                open={blockOpen}
                onClose={() => handleBlockDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>{isBlocked ? "UnBlock" : "Block"} User</h3>

                    <input
                        name="msg"
                        placeholder={'Message'}
                        onChange={(e) => {
                            SetBlockReason(e.target.value)
                        }}
                        type="text"
                        value={blockReason}
                        className="inputTag m10"
                    />

                    <p>
                        Are your Sure you want to {isBlocked ? "UnBlock" : "Block"} the User
                    </p>
                    <div className='dFlex'>
                        <div className='editButton' onClick={() => handleBlock(1)}>Yes</div>
                        <div className='editButton' onClick={() => handleBlockDelete()}>No</div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={stop_purchaseOpen}
                onClose={() => handleBlockDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>{isPurchase ? "stop_purchase" : "Purchase"} User</h3>
                    <p>
                        Are your Sure you want to {isPurchase ? "Enable" : "Disable"} purchase
                    </p>
                    <div className='dFlex'>
                        <div className='editButton' onClick={() => handleBlock(2)}>Yes</div>
                        <div className='editButton' onClick={() => handleBlockDelete()}>No</div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={isScratchGive}
                onClose={() => handleBlockDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>Scratch Card</h3>
                    <div className="pRelative w300">
                        <input
                            type="text"
                            name="name"
                            placeholder='Number Of ScratchCard'
                            onChange={(e) => {
                                setNoOFScratchCard(e.target.value)
                            }}
                            value={noOFScratchCard}
                            className="inputTag partnerInput"
                        />
                    </div>
                    <div className='dFlex'>
                        <div className='editButton' onClick={() => giveScratch()}>Submit</div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={isSpinWheelGive}
                onClose={() => handleBlockDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>Spin Wheel</h3>
                    <div className="pRelative w300">
                        <input
                            type="text"
                            name="name"
                            placeholder='Number Of SpinWheel'
                            onChange={(e) => {
                                setNoOFSpinWheel(e.target.value)
                            }}
                            value={noOFSpinWheel}
                            className="inputTag partnerInput"
                        />
                    </div>
                    <div className='dFlex'>
                        <div className='editButton' onClick={() => giveSpinWheel()}>Submit</div>
                    </div>
                </div>
            </Dialog>

            <Dialog
                open={openChangeStatus}
                onClose={() => handleBlockDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <p>
                        Are your Sure You Want To Make This A Invalid Transactions
                    </p>
                    <div className='dFlex'>
                        <div className='editButton' onClick={() => { handleStatusTypeChange() }} >Yes</div>
                        <div className='editButton' onClick={() => handleBlockDelete()}>No</div>
                    </div>
                </div>
            </Dialog>


        </>
    )
}

export default UserTransaction