import React, { useState, useEffect } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import { ReactComponent as Add } from "../assets/images/add.svg"
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { Autocomplete } from "@mui/material";
import { TextField } from "@material-ui/core";
import { styled } from '@mui/material/styles';
import Chip from '@mui/material/Chip';
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import Paper from '@mui/material/Paper';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});
const ListItem = styled('li')(({ theme }) => ({
    margin: theme.spacing(0.5),
}));

const MetaConfig = () => {
    const [editable, setEditable] = useState(false)
    const [metaConfig, setMetaConfig] = useState({
        "signupBonusWeb": "",
        "signupBonusMobile": "",
        "referToBonus": "",
        "referByBonus": "",
        "minScratchValue": "",
        "maxScratchValue": "",
        "supportNumber": "",
        "amount": [],
        "photo": "",
        "otp_service": 0,
        "app_version": 1,
        "in_maintenance": false,
        "conversion": {
            "rupee": 0,
            "dollar": 0
        },
        "banner": [
            {
                image: "",
                link: "",
                is_self: true,
                updatedOrAddedAt: ""
            }
        ],
        "active_leaderBoard": [],
        "reward_page_banner": [
            {
                link: "",
                mobile_image: "",
                desktop_image: "",
                is_self: true,
                updatedOrAddedAt: ""

            }
        ],

        "user_image": {
            "leader_board_top3": "",
            "reward_daily_image": "",
            "reward_spin_image": "",
            "reward_scratch_image": ""
        },
        "login_rewards": {
            "day1": {
                reward: 0,
                scratch: false
            },
            "day2": {
                reward: 0,
                scratch: false
            },
            "day3": {
                reward: 0,
                scratch: false
            },
            "day4": {
                reward: 0,
                scratch: false
            },
            "day5": {
                reward: 0,
                scratch: false
            },
            "day6": {
                reward: 0,
                scratch: false
            },
            "day7": {
                reward: 0,
                scratch: false
            },
        },
        "bannerSlot": {
            "slot1": [
                { "url": "", "partnerId": "", "link": "", "updatedOrAddedAt": "" }
            ],
            "slot2": [
                { "url": "", "partnerId": "", "link": "", "updatedOrAddedAt": "" }
            ],
            "slot3": [
                { "url": "", "partnerId": "", "link": "", "updatedOrAddedAt": "" }
            ],
            "slot4": [
                { "url": "", "partnerId": "", "link": "", "updatedOrAddedAt": "" }
            ],
            "slot5": [
                { "url": "", "partnerId": "", "link": "", "updatedOrAddedAt": "" }
            ]
        },

        "ads_limit": {
            "liftoffInterstitial": 0,
            "unityInterstitial": 0
        },
        "guideVideos": [
            {
                "title": "",
                "videoType": 1,
                "url": "",
                "updatedOrAddedAt": ""
            }
        ]
    })


    const [allProvider, setAllProvider] = useState([]);
    const [slotData, setSlotData] = useState({ "url": "", "partnerId": "", "link": "" })
    const [slotEditable, setSlotEditable] = useState(false)
    const [slotIndex, setSlotIndex] = useState(0)
    const [openBannerSlot, setOpenBannerSlot] = useState(false)
    const [bannerSlotNumber, setBannerSlotNumber] = useState(0)

    const [videoData, setVideoData] = useState({ 'title': '', videoType: 1, url: "" })
    const [openVideo, setVideoOpen] = useState(false)
    const [videoEditable, setVideoEditable] = useState(false)
    const [videoIndex, setVideoIndex] = useState(0)


    const handleAddGuideVideo = () => {
        const currentDate = new Date().toISOString();

        if (!videoData?.title || !videoData?.videoType || !videoData?.url) {
            alert("Please Enter required field")
            return
        }
        setMetaConfig(prevMetaConfig => ({
            ...prevMetaConfig,
            guideVideos: [...(prevMetaConfig.guideVideos || []),  { ...videoData, updatedOrAddedAt: currentDate }]
        }));

        setVideoOpen(false)
        setVideoData({
            title: "",
            videoType: 1,
            url: "",
            updatedOrAddedAt: ""
        })
    }

    const handleVideoUpdate = () => {
        const currentDate = new Date().toISOString();

        if (!videoData?.title || !videoData?.videoType || !videoData?.url) {
            alert("Please Enter required field")
            return
        }

        let updated = [...metaConfig.guideVideos];
        updated[videoIndex] = { ...videoData, updatedOrAddedAt: currentDate };
        setMetaConfig({
            ...metaConfig,
            guideVideos: updated,
        });
        setVideoOpen(false);
        setVideoData({
            title: "",
            videoType: 1,
            url: ""
        })
        setVideoEditable(false)

    }

    const handleVideoDelete = (index) => {
        let tempArray = [...metaConfig?.guideVideos];
        tempArray.splice(index, 1);
        setMetaConfig({
            ...metaConfig,
            guideVideos: tempArray,
        });
    }


    const editSlotData = () => {
        const currentDate = new Date().toISOString();
        let updatedBannerSlot = { ...metaConfig.bannerSlot };
        let slot = '';

        switch (bannerSlotNumber) {
            case 1:
                slot = 'slot1';
                break;
            case 2:
                slot = 'slot2';
                break;
            case 3:
                slot = 'slot3';
                break;
            case 4:
                slot = 'slot4';
                break;
            case 5:
                slot = 'slot5';
                break;
            default:
                break;
        }

        let singSlot = updatedBannerSlot[slot];
        singSlot[slotIndex] = {...slotData ,updatedOrAddedAt:currentDate};

        setMetaConfig({
            ...metaConfig,
            bannerSlot: {
                ...metaConfig.bannerSlot,
                [slot]: singSlot
            }
        });
        setOpenBannerSlot(false)
        setSlotEditable(false)
        setSlotData({})
    };

    const handleAddSlot = () => {
        const currentDate = new Date().toISOString();

        if (bannerSlotNumber == 1) {

            const updatedBannerSlot = { ...metaConfig.bannerSlot };
            if (!updatedBannerSlot["slot1"]) {
                updatedBannerSlot["slot1"] = [];
            }
            updatedBannerSlot["slot1"].push({ ...slotData, updatedOrAddedAt:currentDate});
            setMetaConfig({
                ...metaConfig,
                bannerSlot: updatedBannerSlot
            });
        }

        if (bannerSlotNumber == 2) {
            const updatedBannerSlot = { ...metaConfig.bannerSlot };
            if (!updatedBannerSlot["slot2"]) {
                updatedBannerSlot["slot2"] = [];
            }
            updatedBannerSlot["slot2"].push({ ...slotData, updatedOrAddedAt:currentDate});
            setMetaConfig({
                ...metaConfig,
                bannerSlot: updatedBannerSlot
            });
        }

        if (bannerSlotNumber == 3) {
            const updatedBannerSlot = { ...metaConfig.bannerSlot };
            if (!updatedBannerSlot["slot3"]) {
                updatedBannerSlot["slot3"] = [];
            }
            updatedBannerSlot["slot3"].push({ ...slotData, updatedOrAddedAt:currentDate});
            setMetaConfig({
                ...metaConfig,
                bannerSlot: updatedBannerSlot
            });
        }
        if (bannerSlotNumber == 4) {
            const updatedBannerSlot = { ...metaConfig.bannerSlot };
            if (!updatedBannerSlot["slot4"]) {
                updatedBannerSlot["slot4"] = [];
            }

            updatedBannerSlot["slot4"].push({ ...slotData, updatedOrAddedAt:currentDate});
            setMetaConfig({
                ...metaConfig,
                bannerSlot: updatedBannerSlot
            });
        }

        if (bannerSlotNumber == 5) {
            const updatedBannerSlot = { ...metaConfig.bannerSlot };
            if (!updatedBannerSlot["slot5"]) {
                updatedBannerSlot["slot5"] = [];
            }

            updatedBannerSlot["slot5"].push({ ...slotData, updatedOrAddedAt:currentDate});

            setMetaConfig({
                ...metaConfig,
                bannerSlot: updatedBannerSlot
            });
        }

        setOpenBannerSlot(false)
        setSlotData({})

    }

    const handleDeleteSlotItem = (slotNumber, slotIndex) => {
        let updatedBannerSlot = { ...metaConfig.bannerSlot };
        let slot = '';

        switch (slotNumber) {
            case 1:
                slot = 'slot1';
                break;
            case 2:
                slot = 'slot2';
                break;
            case 3:
                slot = 'slot3';
                break;
            case 4:
                slot = 'slot4';
                break;
            case 5:
                slot = 'slot5';
                break;
            default:
                break;
        }

        let singSlot = updatedBannerSlot[slot];
        singSlot.splice(slotIndex, 1);

        setMetaConfig({
            ...metaConfig,
            bannerSlot: {
                ...metaConfig.bannerSlot,
                [slot]: singSlot
            }
        });
    }


    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }

    const [loginOpen, setLoginOpen] = useState(false)
    const [addDay, setDay] = useState({
        day: 0,
        reward: 0,
        scratch: false
    })
    const [loginEditable, setLoginEditable] = useState(false)

    const addLoginDay = () => {
        console.log("adding day line 469")
        const validDays = ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7'];
        if (validDays.includes(addDay.day)) {
            const updatedLoginRewards = {
                ...metaConfig.login_rewards,
                [addDay.day]: {
                    reward: addDay.reward,
                    scratch: addDay.scratch
                }
            };
            setMetaConfig({
                ...metaConfig,
                login_rewards: updatedLoginRewards
            });
            setLoginOpen(false)
            setDay({
                day: 0,
                reward: 0,
                scratch: false
            })
        } else {
            alert("day should be between day1 to day7")
        }
    };

    const updateLoginDay = () => {
        const validDays = ['day1', 'day2', 'day3', 'day4', 'day5', 'day6', 'day7'];
        if (validDays.includes(addDay.day)) {
            const updatedLoginRewards = {
                ...metaConfig.login_rewards,
                [addDay.day]: {
                    reward: addDay.reward,
                    scratch: addDay.scratch
                }
            };
            setMetaConfig({
                ...metaConfig,
                login_rewards: updatedLoginRewards
            });
            setLoginOpen(false)
            setDay({})
            // 
        } else {
            alert("day should be between day1 to day7")
        }

        setLoginEditable(false)
    }

    const [rewardBanner, setRewardBanner] = useState({
        mobile_image: "",
        link: "",
        desktop_image: "",
        is_self: true
    })
    const [isOpen, SetIsOpen] = useState(false)
    const [rewardEditable, setRewardEditable] = useState(false)
    const [index, setIndex] = useState(0)

    const handleDeleteRewardBanner = (index) => {
        let tempArray = [...metaConfig.reward_page_banner];
        tempArray.splice(index, 1);
        setMetaConfig({
            ...metaConfig,
            reward_page_banner: tempArray,
        });

    }

    const [open, setOpen] = useState(false)
    const [banner, setBanner] = useState({
        image: "",
        link: "",
        is_self: true
    })
    const [bannerEditabale, setBannerEditable] = useState(false)
    const [bannerIndex, setBannerIndex] = useState(0)

    const handleDeleteBanner = (index) => {
        let tempArray = [...metaConfig.banner];
        tempArray.splice(index, 1);
        setMetaConfig({
            ...metaConfig,
            banner: tempArray,
        });
    }
    const addRewardbanner = () => {
        const currentDate = new Date().toISOString();
        setMetaConfig({
            ...metaConfig, reward_page_banner: [{ ...rewardBanner, updatedOrAddedAt: currentDate }, ...metaConfig.reward_page_banner],
        })
        SetIsOpen(false);
        setRewardBanner({ mobile_image: "", desktop_image: "", link: "", is_self: true, updatedOrAddedAt: "" })
    }
    const updateRewardbanner = () => {
        const currentDate = new Date().toISOString();

        let updated = [...metaConfig.reward_page_banner];
        updated[index] = { ...rewardBanner, updatedOrAddedAt: currentDate };
        setMetaConfig({
            ...metaConfig,
            reward_page_banner: updated,
        });
        SetIsOpen(false);
        setRewardBanner({ mobile_image: "", desktop_image: "", link: "", is_self: true, updatedOrAddedAt: "" })

    }

    const handleClose = () => {
        setOpen(false)
        setBanner({})
        SetIsOpen(false)
        setRewardBanner({})
        setLoginOpen(false)
        setDay({})
        setRewardEditable(false)
        setBannerEditable(false)
        setLoginEditable(false)
        setOpenBannerSlot(false)
        setSlotData({})
        setSlotEditable(false)
        setVideoOpen(false)
        setVideoEditable(false)
        setVideoData({
            title: "",
            videoType: 1,
            url: ""
        })
    }

    const handleImageUpload = async (e, type) => {
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const file = e.target.files[0];

        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            photo.append("photo", selectedFile);

            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    switch (type) {
                        case 'leaderboardimage':
                            console.log(`${type} image`);
                            setBanner({ ...banner, image: res.data.response.url })
                        case 'rewardMobileImage':
                        case 'rewardDesktopImage':
                            console.log(`${type} image`);
                            setRewardBanner({ ...rewardBanner, [type === 'rewardMobileImage' ? 'mobile_image' : 'desktop_image']: res.data.response.url });
                            break;
                        case 1:
                        case 2:
                        case 3:
                        case 4:
                        case 5:
                            console.log(`slot${type}image`);
                            setSlotData({ ...slotData, url: res.data.response.url });
                            break;
                        case 'top3image':
                        case 'dailyImage':
                        case 'spinImage':
                        case 'scratchImage':
                            console.log(`${type}`, res.data.response.url);
                            const key = type === 'top3image' ? "leader_board_top3" : type === 'dailyImage' ? 'reward_daily_image' : type === 'spinImage' ? 'reward_spin_image' : 'reward_scratch_image';
                            setMetaConfig({
                                ...metaConfig,
                                user_image: {
                                    ...metaConfig.user_image,
                                    [key]: res.data.response.url
                                }
                            });
                            break;
                        case 'spinWheelImage':
                            setMetaConfig({ ...metaConfig, photo: res.data.response.url })
                            break;
                        default:
                            break;
                    }

                })
                .catch((err) => console.log(err));
        }
    };

    const updatebannerMeta = () => {
        const currentDate = new Date().toISOString();
        let updated = [...metaConfig.banner];
        updated[bannerIndex] = {
            ...banner,
            updatedOrAddedAt: currentDate
        };

        setMetaConfig({
            ...metaConfig,
            banner: updated,
        });

        setOpen(false);
        setBanner({ image: "", link: "", is_self: true, updatedOrAddedAt: "" });
    }


    const addbanner = () => {
        const currentDate = new Date().toISOString();
        setMetaConfig({
            ...metaConfig,
            banner: [
                { ...banner, updatedOrAddedAt: currentDate },
                ...metaConfig.banner
            ],
        });

        setOpen(false);
        setBanner({ image: "", link: "", is_self: true, updatedOrAddedAt: "" });
    }



    let allOtpService = [{ value: 0, name: 'GrowSaas' }, { value: 1, name: 'Fast2Sms' }]
    const [chipData, setChipData] = React.useState([]);
    const [chipInput, setChipInput] = useState('')
    const handleAdd = () => {
        if (chipInput === '' || chipInput < 0 || isNaN(chipInput)) {
            alert("Please Enter Valid Amount")
            return
        }
        let temp = [...chipData];
        temp.push(chipInput)
        setChipData(temp)
        setMetaConfig({ ...metaConfig, amount: temp })
        setChipInput('')
    }

    const handleDelete = (item, index) => () => {
        let updated = [...chipData];
        updated.splice(index, 1)
        setChipData(updated)
        setMetaConfig({ ...metaConfig, amount: updated })
    };


    const renderInputField = (fieldName, label, placeholder) => (
        <div className='editCover metaCover' key={fieldName}>
            <p>{label}</p>
            <input
                type='text'
                name={fieldName}
                placeholder={placeholder}
                disabled={!editable}
                onChange={(e) => setMetaConfig({ ...metaConfig, [fieldName]: e.target.value })}
                value={metaConfig[fieldName]}
                className="inputTag metaclass"
            />
        </div>
    );

    const inputFields = [
        { fieldName: 'signupBonusWeb', label: 'Signup Bonus Web', placeholder: 'signupBonusWeb' },
        { fieldName: 'app_version', label: 'App Version', placeholder: 'maxScratchValue' },
        { fieldName: 'signupBonusMobile', label: 'Signup Bonus Mobile', placeholder: 'signupBonusMobile' },
        { fieldName: 'referToBonus', label: 'Refer To Bonus', placeholder: 'referToBonus' },
        { fieldName: 'referByBonus', label: 'Refer By Bonus', placeholder: 'referByBonus' },
        { fieldName: 'minScratchValue', label: 'MinScratch Value', placeholder: 'minScratchValue' },
        { fieldName: 'maxScratchValue', label: 'Max Scratch Value', placeholder: 'maxScratchValue' },
        { fieldName: 'supportNumber', label: 'Support Number', placeholder: 'support number' },
    ];

    const handleCheckboxChange = (value) => {
        if (metaConfig.active_leaderBoard.includes(value)) {
            setMetaConfig({
                ...metaConfig,
                active_leaderBoard: metaConfig.active_leaderBoard.filter(val => val !== value)
            });
        } else {
            setMetaConfig({
                ...metaConfig,
                active_leaderBoard: [...metaConfig.active_leaderBoard, value]
            });
        }
    };

    const renderCheckbox = (value, label) => (
        <React.Fragment key={value}>
            <input
                type="checkbox"
                disabled={!editable}
                checked={metaConfig?.active_leaderBoard?.includes(value)}
                className="rounded"
                onChange={() => handleCheckboxChange(value)}
                value={value}
            />
            <label>{label}</label>
        </React.Fragment>
    );

    const SlotTable = ({ slotNumber, metaConfig, editable, handleDeleteSlotItem }) => {
        const slotData = metaConfig?.bannerSlot[`slot${slotNumber}`] || [];

        return (
            <div className='rewardleaderboard'>
                <p className='rewards'>Add Slot {slotNumber}<Add className='addReward' onClick={() => {
                    if (editable) {
                        setOpenBannerSlot(true);
                        setBannerSlotNumber(slotNumber);
                    }
                }} /></p>

                <table className='rewardTable2'>
                    <thead>
                        <tr>
                            <th>Image</th>
                            <th>PartnerId</th>
                            <th>Link</th>
                            <th>Edit</th>
                            <th>Delete</th>
                        </tr>
                    </thead>
                    <tbody className='rewardTabTbody'>
                        {slotData?.map((e, i) => (
                            <tr key={i}>
                                <td><img src={e?.url} alt="leader_board_top3" /></td>
                                <td>{e?.partnerId}</td>
                                <td className='scrollable'>{e?.link}</td>
                                <td>
                                    <Edit onClick={() => {
                                        if (editable) {
                                            setSlotEditable(true);
                                            setBannerSlotNumber(slotNumber);
                                            setSlotIndex(i);
                                            setSlotData(e);
                                            setOpenBannerSlot(true);
                                        }
                                    }} />
                                </td>
                                <td>
                                    <Delete onClick={() => {
                                        if (editable) {
                                            handleDeleteSlotItem(slotNumber, i);
                                        }
                                    }} />
                                </td>
                            </tr>
                        ))}
                    </tbody>
                </table>
            </div>
        );
    };

    const ImageUpload = ({ label, id, disabled, onChange, src }) => (
        <div className='editCover editBio m10 meta-imgages' >
            <p>{label}</p>
            <div className="pRelative">
                <label htmlFor={id}>
                    <Picadd className="addImgae" />
                    <input
                        type="file"
                        id={id}
                        name="photo"
                        multiple
                        disabled={disabled}
                        onChange={onChange}
                        className="filetag"
                    />
                </label>
            </div>
            <div>
                <img src={src} alt={label} className='spinWheel_image' />
            </div>
        </div>
    );


    function getMetaConfig() {
        axiosClient
            .get("/findmetaconfig")
            .then((res) => {
                setMetaConfig(res.data.response)
                setChipData(res.data.response.amount)
            })
            .catch(((error) => console.log(error)))
    }

    function handleEdit() {
        axiosClient
            .put("/editmetaconfig", metaConfig)
            .then((res) => {
                console.log("res.data.response", res.data.response)
                if (res.data.status) {
                    alert("Meta Config Edit Successfully")
                    setEditable(false)
                }
                else {
                    alert(res?.data?.message)
                }
            })
            .catch((error) => console.log(error))
    }

    useEffect(() => {
        getMetaConfig()
        getPartner()
    }, [editable])

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"metaconfig"} />
            <div className='screen'>
                <div className='profileInfoBox metaConfigBox'>
                    <h3 className='infoHeading'> {editable ? "Edit MetaConfig Information" : "MetaConfig Information"}</h3>

                    <div className='dataTable'>
                        {inputFields.map(field => renderInputField(field.fieldName, field.label, field.placeholder))}

                        <div className='editCover metaCover'>
                            <p>
                                Otp Service
                            </p>
                            <Autocomplete
                                disablePortal
                                disabled={editable ? false : true}
                                value={allOtpService.find(option => option.value === metaConfig.otp_service)}
                                onChange={(event, newValue) => {
                                    setMetaConfig({ ...metaConfig, otp_service: newValue?.value })
                                }}
                                options={allOtpService}
                                className='otpServiceMetaConfig'

                                getOptionLabel={option => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="OTP"
                                    />
                                )}
                            />
                        </div>
                        <div className='editCover metaCover'>
                            <p>
                                Amount
                            </p>
                            <input
                                type='text'
                                name="amount"
                                disabled={editable ? false : true}
                                onChange={(e) => {
                                    setChipInput(e.target.value)
                                }}

                                value={chipInput}
                                placeholder={'amount'}
                                className="inputTag metaclass"
                            />
                            <Paper
                                sx={{
                                    display: 'flex',
                                    justifyContent: 'center',
                                    flexWrap: 'wrap',
                                    listStyle: 'none',
                                    p: 0.5,
                                    m: 0,
                                }}
                                component="ul"
                            >
                                {chipData.map((data, index) => {
                                    return (
                                        <ListItem key={data.key}>
                                            <Chip

                                                label={data}
                                                disabled={editable ? false : true}


                                                onDelete={handleDelete(data, index)}
                                            />
                                        </ListItem>
                                    );
                                })}
                            </Paper>

                            {/* <div className='addChip'> */}
                            <div className='addChip'
                                disabled={editable ? true : true}

                                onClick={() => { if (editable) { handleAdd() } }} >

                                add</div>
                            {/* </div> */}

                        </div>
                        <div className='activeLeaderboard'>
                            <p>Active LeaderBaord :</p>
                            {renderCheckbox(1, "Daily")}
                            {renderCheckbox(2, "Weekly")}
                            {renderCheckbox(3, "Monthly")}
                            {renderCheckbox(4, "All")}
                        </div>
                        <div className='editCover metaCover'>
                            <p>In Maintenance</p>
                            <input
                                type="radio"
                                disabled={!editable}
                                checked={metaConfig?.in_maintenance === true ? true : false}
                                className="rounded"
                                onChange={(e) => {
                                    setMetaConfig({ ...metaConfig, in_maintenance: true })
                                }}
                                value={true}
                            />
                            <span style={{ "margin-right": "10px" }}>On</span>


                            <input
                                type="radio"
                                disabled={!editable}
                                checked={metaConfig?.in_maintenance === false ? true : false}
                                className="rounded"
                                onChange={(e) => {
                                    setMetaConfig({ ...metaConfig, in_maintenance: false })
                                }}
                                value={false}
                            />
                            <span>Off</span>


                        </div>
                    </div>

                    <div className="ads-limit">
                        <p className='adslimitPara'>Ads Limit</p>
                        <div className="internal_limits">
                            <div className='intern-div-1'>
                                <p>Liftoff Interstitial</p>
                                <input
                                    type="text"
                                    name=""
                                    id='internal_limits'
                                    disabled={!editable}
                                    onChange={(e) => {
                                        setMetaConfig(prevState => ({
                                            ...prevState,
                                            ads_limit: {
                                                ...prevState.ads_limit,
                                                liftoffInterstitial: Number(e.target.value)
                                            }
                                        }));
                                    }}
                                    value={metaConfig?.ads_limit?.liftoffInterstitial || ''}
                                    placeholder='Enter Liftoff'
                                />
                            </div>
                            <div className='intern-div-1'>
                                <p>Unity Interstitial</p>
                                <input
                                    type="text"
                                    disabled={!editable}
                                    name=""
                                    id='internal_fghj'
                                    onChange={(e) => {
                                        setMetaConfig(prevState => ({
                                            ...prevState,
                                            ads_limit: {
                                                ...prevState.ads_limit,
                                                unityInterstitial: Number(e.target.value)
                                            }
                                        }));
                                    }}
                                    value={metaConfig?.ads_limit?.unityInterstitial || ''}
                                    placeholder='Enter Unity'
                                />
                            </div>
                        </div>
                    </div>

                    <div className="ads-limit">
                        <p className='adslimitPara'>Conversion</p>
                        <div className="internal_limits">
                            <div className='intern-div-1'>
                                <p>In Rupee </p>
                                <input
                                    type="text"
                                    name=""
                                    id='in-rupee'
                                    disabled={!editable}
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setMetaConfig(prevState => ({
                                            ...prevState,
                                            conversion: {
                                                ...prevState.conversion,
                                                // rupee: {$numberDecimal: Number(e.target.value)}
                                                rupee: value ? Number(value) : 0
                                            }
                                        }));
                                    }}
                                    // value={metaConfig?.conversion?.rupee?.$numberDecimal || "0"}
                                    value={metaConfig?.conversion?.rupee?.$numberDecimal || metaConfig?.conversion?.rupee || "0"}
                                />
                            </div>
                            <div className='intern-div-1'>
                                <p>In Dollor</p>
                                <input
                                    type="text"
                                    disabled={!editable}
                                    name=""
                                    id='in-dollor'
                                    onChange={(e) => {
                                        const value = e.target.value;
                                        setMetaConfig(prevState => ({
                                            ...prevState,
                                            conversion: {
                                                ...prevState.conversion,
                                                // dollar: {$numberDecimal: Number(e.target.value)}
                                                dollar: value ? Number(value) : 0
                                            }
                                        }));
                                    }}
                                    // value={metaConfig?.conversion?.dollar?.$numberDecimal || "0"}
                                    value={metaConfig?.conversion?.dollar?.$numberDecimal || metaConfig?.conversion?.dollar || "0"}
                                />
                            </div>
                        </div>
                    </div>

                    <div className='rewardTable '>
                        <p className='rewards'>LeaderBoard banner <Add className='addReward' onClick={() => {
                            if (editable) {
                                setOpen(true)
                                return
                            }
                        }} /></p>
                        <table className='rewardTable2'>
                            <thead>
                                <tr>
                                    <th>Image</th>
                                    <th>Redirection</th>
                                    <th>Is_Self</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody className='rewardTabTbody'>
                                {
                                    metaConfig?.banner?.map((e, i) => {
                                        return (<React.Fragment key={i}>
                                            <tr>
                                                <td>
                                                    <img src={e.image} alt='bannerImage' />
                                                </td>
                                                <td>
                                                    {e?.link}
                                                </td>
                                                <td>
                                                    {e?.is_self ? "True" : "False"}
                                                </td>
                                                <td>
                                                    <Edit onClick={() => {
                                                        if (editable) {
                                                            setOpen(true); setBannerEditable(true); setBanner(e); setBannerIndex(i);
                                                            return
                                                        }
                                                    }}></Edit>
                                                </td>

                                                <td>
                                                    <Delete onClick={() => {
                                                        if (editable) {
                                                            handleDeleteBanner(i)
                                                            return
                                                        }
                                                    }} />
                                                </td>
                                            </tr>
                                        </React.Fragment>)
                                    })
                                }
                            </tbody>
                        </table>
                    </div>

                    <div className='rewardleaderboard'>
                        <p className='rewards'>Reward LeaderBoard banner <Add className='addReward' onClick={() => {
                            if (editable) {
                                SetIsOpen(true)
                                return
                            }
                        }} /></p>

                        <table className='rewardTable2'>
                            <thead>
                                <tr>
                                    <th>Mobile Image</th>
                                    <th>Desktop Image</th>
                                    <th>Link</th>
                                    <th>Is_Self</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody className='rewardTabTbody'>
                                {metaConfig?.reward_page_banner?.map((e, i) => (
                                    <tr key={i}>
                                        <td>
                                            <img src={e.mobile_image} alt='bannerImage' />
                                        </td>
                                        <td>
                                            <img src={e.desktop_image} alt='bannerImage' />
                                        </td>
                                        <td className='scrollable'>{e?.link}</td>
                                        <td>{e?.is_self ? "True" : "False"}</td>
                                        <td><Edit onClick={() => {
                                            if (editable) {
                                                SetIsOpen(true); setRewardEditable(true); setRewardBanner(e); setIndex(i);
                                            }
                                        }}></Edit></td>
                                        <td>
                                            <Delete onClick={() => {
                                                if (editable) {
                                                    handleDeleteRewardBanner(i)
                                                    return
                                                }
                                            }
                                            } />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>

                    <div className='login-reward-div'>
                        <p className='loginrewards'>Login Rewards <Add className='addReward' onClick={() => {
                            if (editable && Object.keys(metaConfig?.login_rewards)?.length < 7) {
                                setLoginOpen(true)
                                return
                            }
                        }} /></p>

                        <table className='rewardTable2'>
                            <thead>
                                <th>day</th>
                                <th>Reward</th>
                                <th>Scratch</th>
                                <th>Edit</th>
                            </thead>
                            <tbody className='rewardTabTbody'>
                                {
                                    Object.entries(metaConfig?.login_rewards || {}).map(([day, data], i) => (
                                        <React.Fragment key={i}>
                                            <tr>
                                                <td>
                                                    {day}
                                                </td>
                                                <td>
                                                    {data?.reward}
                                                </td>
                                                <td>
                                                    {data?.scratch ? "True" : "False"}
                                                </td>
                                                <td>
                                                    <Edit onClick={() => {
                                                        if (editable) {
                                                            setLoginOpen(true); setLoginEditable(true); setDay({ day, ...data });
                                                            return
                                                        }
                                                    }}></Edit>

                                                </td>
                                            </tr>
                                        </React.Fragment>
                                    ))
                                }
                            </tbody>
                        </table>

                    </div>
                    
                    <div>
                        <SlotTable slotNumber={1} metaConfig={metaConfig} editable={editable} handleDeleteSlotItem={handleDeleteSlotItem} />
                        <SlotTable slotNumber={2} metaConfig={metaConfig} editable={editable} handleDeleteSlotItem={handleDeleteSlotItem} />
                        <SlotTable slotNumber={3} metaConfig={metaConfig} editable={editable} handleDeleteSlotItem={handleDeleteSlotItem} />
                        <SlotTable slotNumber={4} metaConfig={metaConfig} editable={editable} handleDeleteSlotItem={handleDeleteSlotItem} />
                        <SlotTable slotNumber={5} metaConfig={metaConfig} editable={editable} handleDeleteSlotItem={handleDeleteSlotItem} />
                    </div>

                    <div className='rewardleaderboard'>
                        <p className='rewards'>Add Guide Videos<Add className='addReward' onClick={() => {
                            if (editable) {
                                setVideoOpen(true)
                            }
                        }} /></p>

                        {metaConfig?.guideVideos?.length > 0 && <table className='rewardTable2'>
                            <thead>
                                <tr>
                                    <th>Title</th>
                                    <th>Type</th>
                                    <th>URL</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </tr>
                            </thead>
                            <tbody className='rewardTabTbody'>
                                {metaConfig?.guideVideos?.map((video, i) => (
                                    <tr key={i}>
                                        <td>{video?.title}</td>
                                        <td>{video?.videoType === 1 ? "sort" : "video"}</td>
                                        <td className='scrollable'>{video?.url}</td>
                                        <td>
                                            <Edit onClick={() => {
                                                if (editable) {
                                                    setVideoEditable(true)
                                                    setVideoIndex(i)
                                                    setVideoData(video)
                                                    setVideoOpen(true)
                                                }
                                            }} />
                                        </td>
                                        <td>
                                            <Delete onClick={() => {
                                                if (editable) {
                                                    handleVideoDelete(i);
                                                }
                                            }} />
                                        </td>
                                    </tr>
                                ))}
                            </tbody>
                        </table>}
                    </div>



                    <div className='meta-images'>
                        <ImageUpload
                            label="LeaderBoard Top 3 Image"
                            id="user_image1"
                            disabled={!editable}
                            onChange={(e) => handleImageUpload(e, 'top3image')}
                            src={metaConfig?.user_image?.leader_board_top3}
                        />
                        <ImageUpload
                            label="Reward Daily Image"
                            id="daily_image"
                            disabled={!editable}
                            onChange={(e) => handleImageUpload(e, "dailyImage")}
                            src={metaConfig?.user_image?.reward_daily_image}
                        />
                        <ImageUpload
                            label="Reward Spin Image"
                            id="spin_image"
                            disabled={!editable}
                            onChange={(e) => handleImageUpload(e, "spinImage")}
                            src={metaConfig?.user_image?.reward_spin_image}
                        />
                        <ImageUpload
                            label="Reward Scratch Image"
                            id="scratch_image"
                            disabled={!editable}
                            onChange={(e) => handleImageUpload(e, "scratchImage")}
                            src={metaConfig?.user_image?.reward_scratch_image}
                        />
                        <ImageUpload
                            label="Spin Wheel Image"
                            id="spin-wheel-image"
                            disabled={!editable}
                            onChange={(e) => handleImageUpload(e, "spinWheelImage")}
                            src={metaConfig?.photo}
                        />
                    </div>

                    {
                        editable ? <div className='logoutandeditbutton'>
                            <div className='logOutButton' onClick={() => handleEdit()}>
                                <div  >Submit</div>
                            </div>
                        </div> :
                            <div className='logoutandeditbutton ' onClick={() => setEditable(true)}>
                                <div className='logOutButton'>
                                    <div  >Edit</div>
                                </div>
                            </div>
                    }
                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'>{bannerEditabale ? "Edit Banner" : "Add Banner"}</h3>
                    <div className='editCover fullCover'>
                        <p>
                            Add image
                        </p>
                        <div className="pRelative">
                            <label htmlFor="inputbanner">
                                <Picadd className="addImgae" />
                                <add className="addImgae" />
                                <input
                                    type="file"
                                    id="inputbanner"
                                    name="photo"
                                    multiple
                                    disabled={editable ? false : true}
                                    onChange={(e) => handleImageUpload(e, 'leaderboardimage')}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        <img src={banner?.image} alt="" className="banner-image" />
                    </div>

                    <div className='editCover fullCover'>
                        <p>
                            Link
                        </p>
                        <input
                            name="payout"
                            placeholder={'Enter Link'}
                            onChange={(e) => {
                                setBanner({ ...banner, link: e.target.value });
                            }}
                            type="text"
                            value={banner?.link}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover fullCover'>
                        <p>
                            Is_Self
                        </p>
                        <input
                            type="radio"
                            disabled={!editable}
                            checked={banner.is_self == true ? true : false}
                            className="rounded isSelfMeta"
                            value={true}
                            onChange={(e) => {
                                setBanner({ ...banner, is_self: e.target.value == 'true' ? true : false });
                            }}
                        />
                        <label>true</label>

                        <input
                            type="radio"
                            disabled={!editable}
                            checked={banner.is_self === false ? true : false}
                            className="rounded isSelfMeta"
                            value={false}
                            onChange={(e) => {
                                setBanner({ ...banner, is_self: e.target.value == 'false' ? false : true });
                            }}

                        />
                        <label>false</label>
                    </div>

                    <div className='addButton' onClick={() => { bannerEditabale ? updatebannerMeta() : addbanner() }}>{bannerEditabale ? "Edit" : "Add"}</div>
                </div>
            </Dialog>

            <Dialog
                open={loginOpen}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'> Add Login Reward </h3>
                    <div className='editCover fullCover'>
                        <p>
                            Day
                        </p>
                        <input
                            name="payout"
                            placeholder={'Enter Day'}
                            disabled
                            onChange={(e) => {
                                setDay({ ...addDay, day: e.target.value })
                            }}
                            type="text"
                            value={addDay?.day}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover'>
                        <p>
                            Rewards
                        </p>
                        <input
                            name="payout"
                            placeholder={'Enter Reward'}
                            onChange={(e) => {
                                setDay({ ...addDay, reward: e.target.value })
                            }}
                            type="text"
                            value={addDay?.reward}
                            className="inputTag"
                        />
                    </div>


                    <div className='editCover fullCover'>
                        <p>
                            Is Scratch
                        </p>
                        <input
                            type="radio"
                            disabled={!editable}
                            checked={addDay?.scratch == true ? true : false}
                            className="rounded isSelfMeta"
                            value={true}
                            onChange={(e) => {
                                setDay({ ...addDay, scratch: e.target.value == 'true' ? true : false });
                            }}
                        />
                        <label>true</label>

                        <input
                            type="radio"
                            disabled={!editable}
                            checked={addDay.scratch == false ? true : false}
                            className="rounded isSelfMeta"
                            value={false}
                            onChange={(e) => {
                                setDay({ ...addDay, scratch: e.target.value == 'false' ? false : true });
                            }}
                        />
                        <label>false</label>
                    </div>

                    <div className='addButton' onClick={() => { loginEditable ? updateLoginDay() : addLoginDay() }}>{loginEditable ? "Save" : "Add"}</div>
                </div>
            </Dialog>

            <Dialog
                open={isOpen}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'>{rewardEditable ? "Edit Reward Banner" : "Reward Page Banner"} </h3>

                    <div className='editCover fullCover'>
                        <p>
                            Add Mobile image
                        </p>
                        <div className="pRelative">
                            <label htmlFor="rewardpage_inputbanner">
                                <Picadd className="addImgae" />
                                <add className="addImgae" />
                                <input
                                    type="file"
                                    id="rewardpage_inputbanner"
                                    name="photo"
                                    multiple
                                    disabled={editable ? false : true}
                                    onChange={(e) => handleImageUpload(e, "rewardMobileImage")}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        <img src={rewardBanner?.mobile_image} alt="" className="banner-image" />
                    </div>

                    <div className='editCover fullCover'>
                        <p>
                            Add Desktop image
                        </p>
                        <div className="pRelative">
                            <label htmlFor="rewards_desktop">
                                <Picadd className="addImgae" />
                                <add className="addImgae" />
                                <input
                                    type="file"
                                    id="rewards_desktop"
                                    name="photo"
                                    multiple
                                    disabled={editable ? false : true}
                                    onChange={(e) => handleImageUpload(e, "rewardDesktopImage")}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        <img src={rewardBanner?.desktop_image} alt="" className="banner-image" />
                    </div>

                    <div className='editCover fullCover'>
                        <p>
                            Link
                        </p>
                        <input
                            name="payout"
                            placeholder={'Enter Link'}
                            onChange={(e) => {
                                setRewardBanner({ ...rewardBanner, link: e.target.value });
                            }}
                            type="text"
                            value={rewardBanner?.link}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover fullCover'>
                        <p>
                            Is_Self
                        </p>
                        <input
                            type="radio"
                            disabled={!editable}
                            checked={rewardBanner.is_self == true ? true : false}
                            className="rounded isSelfMeta"
                            value={true}
                            onChange={(e) => {
                                setRewardBanner({ ...rewardBanner, is_self: e.target.value == 'true' ? true : false });

                            }}
                        />
                        <label>true</label>

                        <input
                            type="radio"
                            disabled={!editable}
                            checked={rewardBanner.is_self == 'false' ? true : false}
                            className="rounded isSelfMeta"
                            value={false}
                            onChange={(e) => {
                                setRewardBanner({ ...rewardBanner, is_self: e.target.value == 'false' ? false : true });
                            }}
                        />
                        <label>false</label>
                    </div>

                    <div className='addButton' onClick={() => { rewardEditable ? updateRewardbanner() : addRewardbanner() }}>{rewardEditable ? "Edit" : "Add"}</div>
                </div>
            </Dialog>

            <Dialog
                open={openBannerSlot}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>Add Slot</h3>

                    <div className='editCover fullCover'>
                        <p>
                            Add Image
                        </p>
                        <div className="pRelative">
                            <label htmlFor="inputbanner">
                                <Picadd className="addImgae" />
                                <add className="addImgae" />
                                <input
                                    type="file"
                                    id="inputbanner"
                                    name="photo"
                                    multiple
                                    disabled={editable ? false : true}
                                    onChange={(e) => handleImageUpload(e, bannerSlotNumber)}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        <img src={slotData.url} alt="" className="banner-image" />

                        <div className='editCover fullCover'>
                            <p>
                                Partner Id
                            </p>

                            <div style={{ width: "250px" }} >
                                <Autocomplete
                                    disablePortal
                                    value={slotData?.name || "Select Partner"}
                                    onChange={(event, newValue) => {
                                        if (newValue) {
                                            setSlotData({ ...slotData, name: newValue?.name, partnerId: newValue?._id, link: newValue?.trackLink })
                                        }
                                    }}
                                    options={allProvider}
                                    getOptionLabel={(option) => typeof option === 'string' ? option : (option.name).toString()}
                                    renderInput={(params) => (
                                        <TextField
                                            {...params}
                                        // label="Provider"
                                        />
                                    )}
                                />
                            </div>
                        </div>

                        <div className='editCover fullCover'>
                            <p>
                                Link
                            </p>
                            <input
                                name="link"
                                placeholder={'Enter Link'}
                                disabled
                                type="text"
                                value={slotData.link}
                                className="inputTag"
                            />
                        </div>
                    </div>
                    <div className='addButton' onClick={() => { slotEditable ? editSlotData() : handleAddSlot() }}>Save</div>
                </div>

            </Dialog>

            <Dialog
                open={openVideo}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'>{videoEditable ? "Edit Guide Video" : "Add Guide Video"} </h3>

                    <div className='editCover fullCover'>
                        <p>
                            Title
                        </p>
                        <input
                            name="video-title"
                            placeholder={'Enter Title'}
                            onChange={(e) => {
                                setVideoData({ ...videoData, title: e.target.value });
                            }}
                            type="text"
                            value={videoData?.title}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover video-radio-type'>
                        <p>
                            Type
                        </p>
                        <div>
                            <input
                                name="video-type"
                                placeholder={'Enter Type'}
                                checked={videoData?.videoType === 1 ? true : false}
                                onChange={(e) => {
                                    setVideoData({ ...videoData, videoType: Number(1) });
                                }}
                                type="radio"
                                value={videoData?.type}
                                className="video-radio"
                            />
                            <span>sort</span>
                            <input
                                name="video-type"
                                placeholder={'Enter Type'}
                                checked={videoData?.videoType === 2 ? true : false}
                                onChange={(e) => {
                                    setVideoData({ ...videoData, videoType: Number(2) });
                                }}
                                type="radio"
                                value={videoData?.type}
                                className="video-radio"
                            />
                            <span>video</span>
                        </div>
                    </div>


                    <div className='editCover fullCover'>
                        <p>
                            URL
                        </p>
                        <input
                            name="video-url"
                            placeholder={'Enter Url'}
                            onChange={(e) => {
                                setVideoData({ ...videoData, url: e.target.value });
                            }}
                            type="text"
                            value={videoData?.url}
                            className="inputTag"
                        />
                    </div>


                    <div className='addButton' onClick={() => { videoEditable ? handleVideoUpdate() : handleAddGuideVideo() }}>{videoEditable ? "Edit" : "Add"}</div>
                </div>
            </Dialog>



        </>
    )
}

export default MetaConfig