import React, { useState } from 'react'
import "../assets/css/Login.css"
import logo from "../assets/images/logo.png"
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import { useNavigate } from 'react-router-dom';
import axiosClient from '../axios';
function Login() {
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const navigate = useNavigate()
    const [formData, setFormData] = useState({
        adminUsername: "",
        password: "",
    });
    const submitForm = async (e) => {
        e.preventDefault();
        await axiosClient
            .post("/adminLogin", formData)
            .then(async (res) => {
                if (res.data.status === false) {
                    alert(res.data.message)
                } else {
                    localStorage.setItem("JWT", JSON.stringify(res.data.jwt));
                    await axiosClient
                        .get("/getAdmin", {
                            headers: {
                                authorization: res.data.jwt
                            }
                        })
                        .then((res) => {
                            localStorage.setItem("admin", JSON.stringify(res.data.response));
                            window.location.replace("/");
                        })
                        .catch((error) =>
                            console.log(error)
                        );
                }
            })
            .catch((error) =>
                console.log(error)
            );
    };
    const showPassword = () => setIsPasswordShown((prev) => !prev);
    return (
        <section className='section loginSection'>
            <div className='loginBox'>
                <img src={logo} alt="logo" />
                <div className='loginHeading'>
                    <h6>Login</h6>
                    <h5>Prorewards Panel</h5>
                </div>
                <div className='inputBox'>
                    <div className='inputCover'>
                        <input
                            placeholder={`Email`}
                            value={formData?.adminUsername}
                            onChange={(event) => {
                                setFormData({ ...formData, adminUsername: event.target.value });
                            }}
                            type="text"
                        />
                    </div>

                    <div className="inputCover">
                        <input
                            type={isPasswordShown ? "text" : "password"}
                            id="password"
                            placeholder={`PassWord`}
                            value={formData?.password}
                            onChange={(event) => {
                                setFormData({ ...formData, password: event.target.value });
                            }}
                        />
                        <button
                            className={`form-group-button${isPasswordShown ? " active" : ""}`}
                            onClick={showPassword}
                            type="button">
                            {isPasswordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </button>
                    </div>

                    <button type="submit" onClick={submitForm} className="submit">
                        Submit
                    </button>
                </div>

                <div className='loginHeading'>
                    <h6 onClick={() => navigate("/register")}>Create an Account</h6>
                </div>
            </div>
        </section>
    )
}

export default Login