import React, { useEffect, useState } from 'react'
import axiosClient from '../axios';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';

import SideBar from './SideBar';
import "../assets/css/profile.css"
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function Profile() {
    const [profile, setProfile] = useState({
        id: "",
        adminUsername: "",
        email: "",
        password: "",
        role: ""
    })


    const [currentPassword, setCurrentPassword] = useState("")
    const [newPassword, setNewPassword] = useState("")
    const [confirmNewPassword, setConfirmNewPassword] = useState("")
    const [open, setOpen] = useState(false);

    function logoutUser() {
        localStorage.clear();
        window.location.reload()
    }
    const getProfile = () => {
        axiosClient
            .get("/getAdmin")
            .then((res) => {
                setProfile(res?.data?.response);
            })
            .catch((error) =>
                console.log(error)
            );
    }
    const sendData = () => {
        if (newPassword !== confirmNewPassword) {
            alert("Confirm Password is not Match")
            return
        }
        if (currentPassword !== profile.password) {
            alert("Current Password is Wrong ,Can not Change the Password")
            return
        }
        const formData = {
            id: profile?._id,
            adminUsername: profile?.adminUsername,
            password: newPassword
        }
        setOpen(false)
        axiosClient
            .put("/editProfile", formData)
            .then((res) => {
                if (res?.data?.status === true) {
                    setProfile(res?.data?.response)
                    alert("Profile updated successfully")
                }

            })
            .catch((error) =>
                console.log(error)
            );
    }

    function handleDelete() {
        setOpen(false)
    }
    useEffect(() => {
        getProfile()
    }, [])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"profile"} />
            <div className='screen'>

                <h2 className='profileScreenHeading'>Profile</h2>

                <div className="profile-form">
                    <div className="profile-row">
                        <p>
                            Name
                        </p>
                        <input
                            name="name"
                            disabled
                            placeholder={'Name'}
                            value={profile?.adminUsername}
                            className="profile-inputBox"
                        />
                    </div>

                    <div className="profile-row">
                        <p>
                            Email
                        </p>
                        <input
                            name="name"
                            disabled
                            placeholder={'Email'}
                            value={profile?.email}
                            className="profile-inputBox"
                        />

                    </div>

                    <div className="profile-row profile-button">
                        <div className='profile-logOutButton' onClick={() => logoutUser()}>
                            {/* <Voucher className='config' /> */}
                            <div className='menuName'>LogOut</div>
                        </div>
                        {/* <div className='editButton' onClick={() => setEditForm(true)}>Edit Profile</div> */}
                        <div className='profile-logOutButton' onClick={() => setOpen(true)}>Change Password</div>

                    </div>
                </div>


                <Dialog
                    open={open}
                    onClose={() => handleDelete()}
                    TransitionComponent={Transition}
                    className="dialogs"
                >
                    <div className='categoryEditBox'>
                        <>
                            <h3>Change Password</h3>
                            <div className="pRelative w300">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder='Enter Current Password'
                                    onChange={(e) => {
                                        setCurrentPassword(e.target.value)
                                    }}
                                    className="inputTag partnerInput"
                                />
                            </div>
                            <div className="pRelative w300">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder='Enter New Password'
                                    onChange={(e) => {
                                        setNewPassword(e.target.value)
                                    }}
                                    className="inputTag partnerInput"
                                />
                            </div>
                            <div className="pRelative w300">
                                <input
                                    type="password"
                                    name="password"
                                    placeholder='Confirm New  Password'
                                    onChange={(e) => {
                                        setConfirmNewPassword(e.target.value)
                                    }}
                                    className="inputTag partnerInput"
                                />
                            </div>
                            {
                                <div className='editButton' onClick={() => sendData()} >Submit</div>
                            }
                        </>

                    </div>
                </Dialog>

            </div>

        </>
    )
}

export default Profile