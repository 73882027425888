import React, { useState } from 'react'
import "../assets/css/Login.css"
import logo from "../assets/images/logo.png"
import VisibilityIcon from "@mui/icons-material/Visibility";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axiosClient from '../axios';
function Register() {
    const [isPasswordShown, setIsPasswordShown] = useState(false);
    const [formData, setFormData] = useState({
        name: "",
        email: "",
        password: "",
        c_password: ""
    });
    const submitForm = async (e) => {
        e.preventDefault();
        if (formData.c_password === formData.password) {
            axiosClient
                .post("/publishers/api/register", formData)
                .then((res) => {
                    if (res.data.status === false) {
                        alert(res.data.message)
                    } else {
                        window.location.replace("/login");
                    }
                })
                .catch((error) =>
                    console.log(error)
                );
        } else {
            alert("Passweord can't be Different")
        }

    };
    const showPassword = () => setIsPasswordShown((prev) => !prev);
    return (
        <section className='section loginSection'>
            <div className='loginBox'>
                <img src={logo} alt="logo" />
                <div className='loginHeading'>
                    <h6>Register</h6>
                    <h5>Voucher Publisher Panel</h5>
                </div>
                <div className='inputBox'>
                    <div className='inputCover'>
                        <input
                            placeholder={`Name`}
                            value={formData?.name}
                            onChange={(event) => {
                                setFormData({ ...formData, name: event.target.value });
                            }}
                            type="text"
                        />
                    </div>
                    <div className='inputCover'>
                        <input
                            placeholder={`Email`}
                            value={formData?.email}
                            onChange={(event) => {
                                setFormData({ ...formData, email: event.target.value });
                            }}
                            type="text"

                        />
                    </div>

                    <div className="inputCover">
                        <input
                            type={isPasswordShown ? "text" : "password"}
                            id="password"
                            placeholder={`Password`}
                            value={formData?.password}
                            onChange={(event) => {
                                setFormData({ ...formData, password: event.target.value });
                            }}
                        />
                        <button
                            className={`form-group-button${isPasswordShown ? " active" : ""}`}
                            onClick={showPassword}
                            type="button">
                            {isPasswordShown ? <VisibilityOffIcon /> : <VisibilityIcon />}
                        </button>
                    </div>

                    <div className="inputCover">
                        <input
                            type={"password"}
                            id="password"
                            placeholder={`Confirm Password`}
                            value={formData?.c_password}
                            onChange={(event) => {
                                setFormData({ ...formData, c_password: event.target.value });
                            }}
                        />
                    </div>

                    <button type="submit" onClick={submitForm} className="submit">
                        Submit
                    </button>
                </div>

            </div>
        </section>
    )
}

export default Register