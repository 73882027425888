import React, { useCallback, useEffect, useState } from 'react'
import SideBar from './SideBar';
import axiosClient from '../axios';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
import moment from 'moment';
import { useNavigate } from 'react-router-dom';
import CSVDownload from "./CSVDownload"
import { ReactComponent as Config } from "../assets/images/config.svg"
import { ReactComponent as Cross } from "../assets/images/cross.svg"
import loader from '../assets/images/loader.gif'
import debounce from "lodash/debounce";
import { dateConvert } from '../dateConverter';
import '../assets/css/userTable.css'
import Pagination from './Pagination';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { TextField } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function AllUsers() {
    const navigate = useNavigate();
    const [filterBox, setFilterBox] = useState(false)
    const [isBlocked, setIsBlocked] = useState(false);
    const [user, setUser] = useState("")
    const [open, setOpen] = useState(false);
    const [totalPage, setTotalPage] = useState(0)
    const [data, setData] = useState([])
    const [isCsvDownload, setIsCsvDownload] = useState(false)
    const [isLoading, setIsLoading] = useState(true)
    const [searchValue, setSearchValue] = useState("")
    const [date, setDate] = useState({
        start: null,
        end: null,
    })
    const [filterInfo, setFilterInfo] = useState({
        page: 0,
        limit: 100,
        s_date: "",
        e_date: "",
        fieldValue: 1, // 0 is for register 1 is for login
        is_Blocked: false,
        purchase: false
    })

    const handleDate = (e, f) => {
        const res = dateConvert(e);
        if (f === 1) {
            setDate({ ...date, start: e })
            setFilterInfo({ ...filterInfo, s_date: res });
            return
        }
        if (f === 2) {
            setDate({ ...date, end: e })
            setFilterInfo({ ...filterInfo, e_date: res });
            return
        }
    }

    const getUsers = async (value) => {
        setIsLoading(false)
        let bodyData = filterInfo
        bodyData.searchValue = value || ""
        await axiosClient
            .post(`/admin/getAllUser`, bodyData)
            .then((res) => {
                if (res.data.statusCode !== 201) {
                    alert(`Error ${res.data.message}`)
                    setFilterInfo({
                        page: 0,
                        limit: 100,
                        s_date: "",
                        e_date: "",
                        fieldValue: 1, // 0 is for register 1 is for login
                        is_Blocked: false,
                        purchase: false
                    })
                    return
                }
                setTotalPage(res?.data?.response?.totalCount)
                setData(res.data?.response?.totalData)
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => {
                setIsLoading(true)
            })
    }

    const handleDelete = () => setOpen(false)

    const handleBlock = () => {
        const formData = {
            userId: user,
            block: !isBlocked,
        }
        axiosClient
            .put(`/blockUser`, formData)
            .then((res) => {
                getUsers();
                setOpen(false);
            })
            .catch((error) =>
                console.log(error)
            );
    }

    const handleSubmit = async () => {
        setFilterBox(false)
        await getUsers()
    }



    const handleCsvDownload = async () => {
        setIsLoading(false)
        let bodyData = filterInfo
        bodyData.searchValue = searchValue || ""
        await axiosClient
            .post(`/admin/downloadUserCsv`, bodyData)
            .then((res) => {
                setIsCsvDownload(false)
                if (filterInfo?.limit > 25000) {
                    handleDownload(res?.data?.response)
                }
                else {
                    handleDownload(res.data?.response[0]?.totalData)
                }
                setFilterInfo({ ...filterInfo, page: 0, limit: 100 })
            })
            .catch((error) =>
                console.log(error)
            ).finally(() => {
                setIsLoading(true)
            })
    }

    const handleDownload = (data) => {
        try {
            const dataToExport = data.map((val, key) => ({
                "S.NO.": (key + 1),
                "UserName": val?.name,
                "Email Id": val?.emailId,
                "Phone Number": val?.phoneNumber,
                "referCode": val?.refer_code,
                "refer through": val?.referThrough[0]?.name,
                "phone updated": val?.is_phone,
                "Earnings": val?.earnings,
                "Earning Type": val?.earning_type == 1 ? "spinWheel" : val?.earning_type == 2 ? "ScratchCard" : val?.earning_type == 3 ? "singup" : val?.earning_type == 4 ? "Weekly" : "null",
                'Last Login At': val?.last_login_at,
                "Registered On": val?.registered_on,
                "Wallet": val?.wallet,
                "Tasks Performed": val?.tasksPerformed,
                "Past Wallet": val?.pastWallet,
                "Is Blocked": val.is_blocked ? "Blocked" : "UnBlock"
            }));
            CSVDownload(dataToExport);
        } catch (error) {
            console.error('Error downloading CSV:', error);
        }
    };

    const handleCsvlimit = () => {
        console.log("handlecsvlimit function")
        setIsCsvDownload(true)
        filterInfo.page = 0
        filterInfo.limit = totalPage
    }

    useEffect(() => {
        if (isCsvDownload) {
            handleCsvDownload()
        }
        else {
            getUsers()
        }

    }, [filterInfo.page, filterInfo.limit])

    const handleSearch = useCallback(
        debounce((value) => {
            setSearchValue(value)
            getUsers(value);
        }, 500),
        []
    );

    const handleChange = (e) => {
        const { value } = e.target;
        setFilterInfo({ ...filterInfo, page: 0, limit: 100 });
        handleSearch(value);
    };

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"users"} />
            <div className="screen">
                <div className='flexClass'>
                    <div className='dFlex w30'>
                        <h2 className='screenHeading catHead mainheading'>Total Users: {totalPage}</h2>
                    </div>
                    <div className="dFlex w30 fEnd">
                        <div className='userCsvDiv'>
                            <button className='userCsvButton'
                                onClick={() => { handleCsvlimit() }}
                            >
                                Download CSV</button>
                        </div>
                        <div className="Usersearchbar autoWidth">
                            <input
                                type="text"
                                placeholder="Search..."
                                onChange={handleChange}
                            />
                        </div>
                        <div className='filterIcon' >
                            {
                                filterBox ?
                                    <Cross onClick={() => setFilterBox(false)} style={{ width: '20px', height: '17px' }} />
                                    : <Config onClick={() => setFilterBox(true)} />
                            }

                            <div className={`filterArea ${filterBox && "active"}`} >
                                <div className='dFlex alignCenter w100 m10'>

                                    <div className='dFlex w30'>
                                        <p className='fHead'>Purchase : </p>
                                    </div>
                                    <div className="dFlex w70">
                                        <span className='dFlex alignCenter'>
                                            <input
                                                type="radio"
                                                checked={filterInfo.purchase}
                                                className="mr3"
                                                value={true}
                                                onChange={(e) => setFilterInfo({ ...filterInfo, purchase: true })}
                                            />
                                            <p className='inline'>True</p>
                                        </span>
                                        <span className='dFlex alignCenter'>
                                            <input
                                                type="radio"
                                                checked={!filterInfo.purchase}
                                                className="mr3"
                                                value={false}
                                                onChange={(e) => setFilterInfo({ ...filterInfo, purchase: false })}
                                            />
                                            <p className='inline'>False</p>
                                        </span>
                                    </div>

                                </div>
                                <div className='dFlex alignCenter w100 m10'>
                                    <div className='dFlex w30'>
                                        <p className='fHead'>Blocked : </p>
                                    </div>
                                    <div className="dFlex w70">
                                        <span className='dFlex alignCenter'>
                                            <input
                                                type="radio"
                                                checked={filterInfo.is_Blocked}
                                                className="mr3"
                                                value={true}
                                                onChange={(e) => setFilterInfo({ ...filterInfo, is_Blocked: true })}
                                            />
                                            <p className='inline'>True</p>
                                        </span>
                                        <span className='dFlex alignCenter'>
                                            <input
                                                type="radio"
                                                checked={!filterInfo.is_Blocked}
                                                className="mr3"
                                                value={false}
                                                onChange={(e) => setFilterInfo({ ...filterInfo, is_Blocked: false })}
                                            />
                                            <p className='inline'>False</p>
                                        </span>
                                    </div>

                                </div>
                                <div className='dFlex alignCenter w100 m10'>
                                    <div className='dFlex w30'>
                                        <p className='fHead'>Sorting : </p>
                                    </div>
                                    <div className="dFlex w70">
                                        <span className='dFlex alignCenter'>
                                            <input
                                                type="radio"
                                                checked={filterInfo.fieldValue === 0}
                                                className="mr3"
                                                value={0}
                                                onChange={(e) => setFilterInfo({ ...filterInfo, fieldValue: Number(e.target.value) })}
                                            />
                                            <p className='inline'>Register</p>
                                        </span>
                                        <span className='dFlex alignCenter'>
                                            <input
                                                type="radio"
                                                checked={filterInfo.fieldValue === 1}
                                                className="mr3"
                                                value={1}
                                                onChange={(e) => setFilterInfo({ ...filterInfo, fieldValue: Number(e.target.value) })}
                                            />
                                            <p className='inline'>Login</p>
                                        </span>
                                    </div>

                                </div>
                                <div className='dFlex alignCenter w100 m10'>
                                    <div className='dFlex w30'>
                                        <p className='fHead'>Start Date : </p>
                                    </div>
                                    <div className="dFlex w70">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="Start Date"
                                                value={date.start}
                                                onChange={(value) => handleDate(value, 1)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                </div>
                                <div className='dFlex alignCenter w100 m10'>
                                    <div className='dFlex w30'>
                                        <p className='fHead'>End Date : </p>
                                    </div>
                                    <div className="dFlex w70">
                                        <LocalizationProvider dateAdapter={AdapterDayjs}>
                                            <DatePicker
                                                label="End Date"
                                                value={date.end}
                                                onChange={(value) => handleDate(value, 2)}
                                                renderInput={(params) => <TextField {...params} />}
                                            />
                                        </LocalizationProvider>
                                    </div>

                                </div>
                                <div className='submitArea'>
                                    <p onClick={() => handleSubmit()}>Submit</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div className='profileInfoBox table'>
                    {isLoading ?
                        <>
                            <div style={{ width: '100%' }} className="container app-body">
                                {data.length > 0 ? <>

                                    <div className="tabel-scrol">
                                        <table id="users">
                                            <thead>
                                                <tr>
                                                    <th>S.NO.</th>
                                                    <th>UserName</th>
                                                    <th>Email Id </th>
                                                    <th>Phone Number</th>
                                                    <th>ReferCode</th>
                                                    <th>Refer through</th>
                                                    <th>Phone updated</th>
                                                    <th>Earnings</th>
                                                    <th>Last Login At</th>
                                                    <th>Registered On</th>
                                                    <th>Wallet</th>
                                                    <th>Tasks Performed</th>
                                                    <th>Past Wallet</th>
                                                    <th>Country Code</th>
                                                    <th>Is Blocked</th>
                                                    <th>Transaction</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {data?.map((val, key) => (
                                                    <tr key={key}>
                                                        <td>{(filterInfo.limit * filterInfo.page) + (key + 1)}</td>
                                                        <td className='clickabuetd' onClick={() => window.open(`/users/transaction/${val._id}`)}>{val?.name}</td>
                                                        <td>{val?.emailId}</td>
                                                        <td>{val?.phoneNumber}</td>
                                                        <td>{val?.refer_code.toUpperCase()}</td>
                                                        <td>
                                                            {val?.referThrough?.length > 0 ?
                                                                <p className='clickable' onClick={() => navigate(`/users/transaction/${val?.referThrough[0]?._id}`)}>
                                                                    {val?.referThrough[0]?.name}
                                                                </p>
                                                                : "Na"
                                                            }</td>
                                                        <td>{val?.is_phone}</td>
                                                        <td>{val?.earnings.toFixed(2)}</td>
                                                        <td>{moment.utc(val?.last_login_at).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                        <td>{moment.utc(val?.registered_on).local().format("DD/MM/YY, h:mm:ss a")}</td>
                                                        <td>{val?.wallet.toFixed(2)}</td>
                                                        <td>{val?.tasksPerformed}</td>
                                                        <td>{val?.pastWallet?.toFixed(2)}</td>
                                                        <td>{val?.countryCode}</td>
                                                        <td onClick={() => { setIsBlocked(val.is_blocked); setOpen(true); setUser(val._id) }}>{val.is_blocked ? "Blocked" : "UnBlock"}</td>
                                                        <td>{<p className='TransactionBox' onClick={() => navigate(`/users/transaction/${val?._id}`)}>View Transaction</p>}</td>
                                                    </tr>
                                                ))}
                                            </tbody>
                                        </table>

                                    </div>

                                    <div>
                                        <Pagination
                                            filterDataInput={filterInfo}
                                            setFilterDataInput={setFilterInfo}
                                            totalPage={totalPage}
                                        />
                                    </div>

                                    
                                    {/* <div className='userTablePagination'>
                                        <label>Rows per page:</label>
                                        <select value={filterInfo.limit} onChange={(e) => setFilterInfo({ ...filterInfo, limit: Number(e.target.value), page: 0 })}>
                                            <option value={10}>10</option>
                                            <option value={50}>50</option>
                                            <option value={100}>100</option>
                                            <option value={500}>500</option>
                                            <option value={1000}>1000</option>
                                            <option value={1500}>1500</option>
                                            <option value={2500}>2500</option>
                                        </select>

                                        <span className='currentPagespan'> Current Page {filterInfo.page + 1}/{Math.ceil(totalPage / filterInfo.limit)} </span>

                                        {filterInfo.page > 0 && (
                                            <button className='paginationButton' onClick={() => setFilterInfo({ ...filterInfo, page: Number(filterInfo.page - 1) })} style={{ backgroundColor: "#2b579a" }}>
                                                Previous
                                            </button>
                                        )}

                                        {filterInfo.page < Math.ceil(totalPage / filterInfo.limit) && (
                                            <button className='paginationButton'
                                                onClick={() => {
                                                    setFilterInfo({ ...filterInfo, page: Number(filterInfo.page + 1) })

                                                }}
                                                disabled={filterInfo.page + 1 >= totalPage / filterInfo.limit}
                                            >
                                                Next
                                            </button>
                                        )}

                                    </div> */}

                                </> :
                                    <>
                                        <h4 className='h5Heading'>No Data Available</h4>
                                    </>}
                            </div>
                        </> :
                        <>
                            <div className='loaderImage'>
                                <img src={loader} alt='loader' />
                            </div>
                        </>
                    }
                </div>
            </div >
            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3>{isBlocked ? "UnBlock" : "Block"} User</h3>
                    <p>
                        Are your Sure you want to {isBlocked ? "UnBlock" : "Block"} the User
                    </p>
                    <div className='dFlex'>
                        <div className='editButton' onClick={() => handleBlock()}>Yes</div>
                        <div className='editButton' onClick={() => handleDelete()}>No</div>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default AllUsers