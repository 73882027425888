import React, { useEffect, useState } from 'react'
import axiosClient from '../axios';
import SideBar from './SideBar';
import "../assets/css/profile.css"
import ColorPickar from '../helperComponent/ColorPickar';
import JoditEditor from "jodit-react";
import { Autocomplete } from "@mui/material";
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import { TextField } from "@material-ui/core";
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import { ReactComponent as Apple } from "../assets/images/apple.svg"
import { ReactComponent as Desktop } from "../assets/images/desktop.svg"
import { ReactComponent as Android } from "../assets/images/android.svg"
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { useLocation, useNavigate } from 'react-router-dom';
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import CustomizedHook from './CustomizedHook';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

function EditOffer() {
    const search = useLocation().search;
    const offerId = new URLSearchParams(search).get("offer_id");
    const options = [
        { value: 1, label: 'Android', image: <Android /> },
        { value: 2, label: 'Desktop', image: <Desktop /> },
        { value: 3, label: 'Apple', image: <Apple /> }
    ];

    const [fileInputKey, setFileInputKey] = useState(Date.now());
    const handleSelectChange = (event) => {
        setFormData({ ...formData, availabelOn: event.target.value })
    };
    const [allCategory, setAllCatgeory] = useState([]);
    const [allProvider, setAllProvider] = useState([]);
    const [providerName, setProviderName] = useState("")
    // const [color1, setColor1] = useState("#ff0000")
    // const [color2, setColor2] = useState("#ff0000")


    const [colors, setColors] = useState({
        color1: "#2b579a",
        color2: "#2b579a"
    })

    const handlefileInput = async (e, type) => {
        setFileInputKey(Date.now());
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const fileSizeInMb = selectedFile.size / (1024 * 1024);
        if (fileSizeInMb > 6) {
            alert('File size is too large. Please choose a file smaller than 6 MB.');
        } else {
            photo.append("photo", e.target.files[0]);
            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    if (type === 1) {
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                coverImage: res.data.response.url
                            }
                        })
                    } else if (type === 2) {
                        setFormData({
                            ...formData, image: {
                                ...formData.image,
                                base: res.data.response.url
                            }
                        })
                    }
                })
                .catch((err) => console.log(err));
        }
    };

    const [open, setOpen] = useState(false)
    const handleClose = () => {
        setOpen(false)
    }
    const [is_voucherActive, setIs_voucherActive] = useState("false");
    const [is_popular, setIs_popular] = useState("false");
    const [is_featured, setis_featured] = useState("false");

    const [formData, setFormData] = useState({
        name: "",
        ratings: 0,
        rewards: [],
        trackLink: "",
        videoLink: "",
        is_active: true,
        is_popular: false,
        is_featured: false,
        description: "",
        important_tips: "",
        small_descriptions: "",
        conditions: "",
        category: "",
        provider: "",
        countries: [],
        availabelOn: [1, 2],
        image: {
            base: "",
            coverImage: ""
        },
        is_easy: false,
        is_carousel: false,
        bg_color: []

    });
    const navigate = useNavigate();
    const editReward = (i) => {
        formData.rewards[i] = {
            level: RewardObject.level,
            payOutAmount: RewardObject.payOutAmount,
            revenueAmount: RewardObject.revenueAmount,
            minutesPlayed: RewardObject.minutesPlayed,
            r_id: RewardObject.r_id
        }
        setOpen(false)
    }

    const popReward = (e) => {
        formData.rewards.pop(e);
        setFormData({ ...formData, rewards: formData.rewards })
    }


    const getCatgeories = () => {
        axiosClient
            .get("/getCategory")
            .then((res) => {
                setAllCatgeory(res.data.response);
            })
            .catch((error) => console.log(error));
    }
    const getOffer = () => {
        axiosClient
            .get(`/admin/oneOffer/${offerId}`)
            .then((res) => {
                const data = res.data.response
                setFormData({
                    name: data.name,
                    ratings: data?.ratings,
                    rewards: data?.rewards,
                    trackLink: data?.trackLink,
                    videoLink: data?.videoLink,
                    is_active: data?.is_active,
                    is_popular: data?.is_popular,
                    is_featured: data?.is_featured,
                    countries: data?.countries,
                    description: data?.description,
                    important_tips: data?.important_tips,
                    conditions: data?.conditions,
                    small_descriptions: data?.small_descriptions,
                    category: data?.category,
                    provider: data?.provider,
                    availabelOn: data?.availabelOn,
                    image: {
                        base: data?.image?.base,
                        coverImage: data?.image?.coverImage
                    },
                    p_offer_id: data.p_offer_id,
                    is_easy: data.is_easy,
                    is_carousel: data.is_carousel,
                    bg_color: data.bg_color

                })
                data.is_active ? setIs_voucherActive("true") : setIs_voucherActive("false")
                data.is_popular ? setIs_popular("true") : setIs_popular("false")
                data.is_featured ? setis_featured("true") : setis_featured("false")

                setProviderName(data?.provider?.name)
                // setColor1(data?.bg_color[0])
                // setColor2(data?.bg_color[1])

                setColors({ ...colors, color1:data?.bg_color[0], color2: data?.bg_color[1] })

            })
            .catch((error) => console.log(error));
    }
    const handleSelectedValuesChange = (values) => {
        const namesArray = values.map(obj => obj);
        setFormData({ ...formData, countries: namesArray })
    };
    const getPartner = () => {
        axiosClient
            .get("/partnersList")
            .then((res) => setAllProvider(res.data.response))
            .catch((error) => console.log(error));
    }
    const sendData = () => {
        if (!formData?.name || !formData?.image || !formData?.trackLink || !formData?.description || !formData?.important_tips || !formData?.conditions || !formData?.small_descriptions || !formData?.countries) {
            alert("Missing Required Field")
            return
        }

        if ((formData?.rewards.length) < 1) {
            alert("Please Enter Rewards")
            return
        }

        formData.bg_color = [colors?.color1, colors?.color2]
        axiosClient
            .put(`admin/editOffer/${offerId}`, formData)
            .then((res) => {
                if (res.data.status === true) {
                    alert("Offer has been edited successfully")
                }
            })
            .catch((error) =>
                console.log(error)
            );
    }
    const [RewardObject, setRewardObject] = useState({
        level: 0,
        payOutAmount: 0,
        revenueAmount: 0,
        minutesPlayed: 0,
        r_id: ""
    })
    const [RewardIndex, setRewardIndex] = useState(0)

    const handlerewardObject = (item, index) => {
        setOpen(true);
        setRewardIndex(index)
        console.log("item", item)
        setRewardObject({
            level: item.level,
            payOutAmount: item.payOutAmount,
            revenueAmount: item.revenueAmount,
            minutesPlayed: item.minutesPlayed,
            r_id: item.r_id
        })
    }
    // const handleColorChange1 = (event) => {
    //     setColor1(event.target.value);
    // };
    // const handleColorChange2 = (event) => {
    //     setColor2(event.target.value);
    // };
    useEffect(() => {
        getCatgeories();
        getOffer();
        getPartner()
    }, [])
    return (
        <>
            <SideBar handleSideBar={true} activeTab={"vouhcer"} />
            <div className='screen'>
                <h2 className='screenHeading'>Edit Vouhcer</h2>
                <div className='vouhcerInfoBox'>
                    <div className='addofferFlex'>
                        <div className='editCover'>
                            <p>
                                Name
                            </p>
                            <input
                                name="name"
                                placeholder={'Name'}
                                onChange={(e) => {
                                    setFormData({ ...formData, name: e.target.value });
                                }}
                                value={formData.name}
                                className="inputTag"
                            />
                        </div>
                        <div className='editCover'>
                            <p>
                                Ratings
                            </p>
                            <input
                                name="Number"
                                placeholder={'Ratings'}
                                onChange={(e) => {
                                    setFormData({ ...formData, ratings: e.target.value });
                                }}
                                value={formData?.ratings?.$numberDecimal}
                                className="inputTag"
                            />
                        </div>
                        <div className='editCover'>
                            <p>
                                P_Offer_Id
                            </p>
                            <input
                                name="p_offer_id"
                                placeholder={'p_offer_id'}
                                onChange={(e) => {
                                    setFormData({ ...formData, p_offer_id: e.target.value });
                                }}
                                value={formData.p_offer_id}
                                className="inputTag"
                            />
                        </div>
                    </div>
                    <div className='editCover editBio'>
                        <p>
                            Track Link
                        </p>
                        <input
                            name="name"
                            placeholder={'TrackLink'}
                            onChange={(e) => {
                                setFormData({ ...formData, trackLink: e.target.value });
                            }}
                            value={formData.trackLink}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover editBio offer-m-top'>
                        <p>
                            Video Link
                        </p>
                        <input
                            name="videoLink"
                            placeholder={'VideoLink'}
                            onChange={(e) => {
                                setFormData({ ...formData, videoLink: e.target.value });
                            }}
                            value={formData.videoLink}
                            className="inputTag"
                        />
                    </div>
                    <div className='addofferFlex offer-m-top'>
                        <div className='editCover'>
                            <p>
                                Is Offer active
                            </p>
                            <div className="pRelative">
                                <input
                                    type="radio"
                                    checked={is_voucherActive === "true" ? true : false}
                                    className="rounded"
                                    value={'true'}
                                    onChange={(e) => {
                                        setIs_voucherActive(e.target.value);
                                        setFormData({ ...formData, is_active: true });
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input

                                    type="radio"
                                    checked={is_voucherActive === "false" ? true : false}
                                    className="rounded"
                                    value={'false'}
                                    onChange={(e) => {
                                        setIs_voucherActive(e.target.value);
                                        setFormData({ ...formData, is_active: false });
                                    }}
                                />                            <p className='inline'>False</p>

                            </div>
                        </div>

                        <div className='editCover'>
                            <p>
                                Is Offer Popular
                            </p>
                            <div className="pRelative">
                                <input

                                    type="radio"
                                    checked={is_popular === "true" ? true : false}
                                    className="rounded"
                                    value={'true'}
                                    onChange={(e) => {
                                        setIs_popular(e.target.value);
                                        setFormData({ ...formData, is_popular: e.target.value == 'true' ? true : false });
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input
                                    type="radio"
                                    checked={is_popular === "false" ? true : false}
                                    className="rounded"
                                    value={'false'}
                                    onChange={(e) => {
                                        setIs_popular(e.target.value);
                                        setFormData({ ...formData, is_popular: false });
                                    }}
                                />                            <p className='inline'>False</p>

                            </div>
                        </div>

                        <div className='editCover'>
                            <p>
                                Is Featured
                            </p>
                            <div className="pRelative">
                                <input
                                    type="radio"
                                    checked={is_featured === "true" ? true : false}
                                    className="rounded"
                                    value={'true'}
                                    onChange={(e) => {
                                        setis_featured(e.target.value);
                                        setFormData({ ...formData, is_featured: e.target.value == 'true' ? true : false });
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input
                                    type="radio"
                                    checked={is_featured === "false" ? true : false}
                                    className="rounded"
                                    value={'false'}
                                    onChange={(e) => {
                                        setis_featured(e.target.value);
                                        setFormData({ ...formData, is_featured: false });
                                    }}
                                />                            <p className='inline'>False</p>

                            </div>
                        </div>
                        <div className='editCover'>
                            <p>
                                IS Easy
                            </p>
                            <div className="pRelative">
                                <input
                                    type="radio"
                                    checked={formData?.is_easy == true ? true : false}
                                    className="rounded"
                                    value={'true'}
                                    onChange={(e) => {
                                        setFormData({ ...formData, is_easy: e.target.value == 'true' ? true : false });
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input
                                    type="radio"
                                    checked={formData?.is_easy === false ? true : false}
                                    className="rounded"
                                    value={'false'}
                                    onChange={(e) => {
                                        setFormData({ ...formData, is_easy: false });
                                    }}
                                />
                                <p className='inline'>False</p>

                            </div>
                        </div>
                        <div className='editCover'>
                            <p>
                                IS Carousel
                            </p>
                            <div className="pRelative">
                                <input
                                    type="radio"
                                    checked={formData?.is_carousel === true ? true : false}
                                    className="rounded"
                                    value={'true'}
                                    onChange={(e) => {
                                        setFormData({ ...formData, is_carousel: e.target.value == 'true' ? true : false });
                                    }}
                                />
                                <p className='inline'>True</p>
                                <input
                                    type="radio"
                                    checked={formData?.is_carousel === false ? true : false}
                                    className="rounded"
                                    value={'false'}
                                    onChange={(e) => {
                                        setFormData({ ...formData, is_carousel: false });
                                    }}
                                />
                                <p className='inline'>False</p>
                            </div>
                        </div>
                    </div>
                    <div className='addofferFlex offer-m-top'>
                        <div className='editCover offerDropown'>
                            <p>
                                Provider
                            </p>
                            <Autocomplete
                                disablePortal
                                onChange={(e, newValue) => {
                                    setFormData({ ...formData, provider: newValue?._id });
                                    setProviderName(newValue?.name)
                                }}
                                options={allProvider}
                                value={allProvider.find(option => option.name === providerName) || null}
                                getOptionLabel={option => option?.name}
                                isOptionEqualToValue={(option, value) => option === value}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Provider"
                                    />
                                )}
                            />
                        </div>
                        <div className='editCover offerDropown '>
                            <p>
                                Category
                            </p>
                            <Autocomplete
                                disablePortal
                                onChange={(event, newValue) => {
                                    setFormData({ ...formData, category: newValue?.name });
                                }}
                                options={allCategory}
                                value={allCategory.find(option => option.name === formData?.category) || null}
                                getOptionLabel={option => option.name}
                                renderInput={(params) => (
                                    <TextField
                                        {...params}
                                        label="Category"
                                    />
                                )}
                            />
                        </div>

                        <div className='editCover offerDropown availableOn'>
                            <p>
                                Available On
                            </p>
                            <FormControl>
                                <Select
                                    className='selectBox'
                                    multiple
                                    value={formData.availabelOn}
                                    onChange={handleSelectChange}
                                    renderValue={(selected) => (
                                        <div>
                                            {selected.map((value) => {

                                                const option = options.find((option) => option.value === value);
                                                return (
                                                    <React.Fragment key={value}>
                                                        <span className='optionImage'>
                                                            {option.image}
                                                        </span>
                                                    </React.Fragment>
                                                );
                                            })}
                                        </div>
                                    )}
                                >
                                    {options.map((option) => (
                                        <MenuItem key={option.value} value={option.value} className="svgApple">
                                            {option.image}
                                            {option.label}
                                        </MenuItem>
                                    ))}
                                </Select>
                            </FormControl>
                        </div>
                    </div>


                    {/* <div className='color_piker'>
                        <div className='picker-section'>
                            <div className='picker-section-color'>

                                <label htmlFor="colorPicker">Choose a color1:</label>
                                <input
                                    type="color"
                                    id="colorPicker"
                                    name="colorPicker"
                                    value={color1}
                                    onChange={handleColorChange1}
                                />
                            </div>
                            <div color='picker-section-color'>
                                <label htmlFor="colorPicker2">Choose a color2:</label>
                                <input
                                    type="color"
                                    id="colorPicker2"
                                    name="colorPicker2"
                                    value={color2}
                                    onChange={handleColorChange2}
                                />

                            </div>
                        </div>

                        <div style={{ background: "red", background: `linear-gradient(to right , ${color1},${color2})` }} className='linear_gradient'>
                        </div>
                    </div> */}

                    <ColorPickar
                        colors={colors}
                        setColors={setColors}
                    />



                    <div className='rewardTable offer-m-top'>
                        <p className='rewards'>Rewards</p>
                        <div className='flexRow'>
                            <h3>Level</h3>
                            <h3>Payout</h3>
                            <h3>Revenue</h3>
                            <h3>Minute Played</h3>
                            <h3>Edit</h3>
                            <h3>Delete</h3>
                        </div>
                        {
                            formData?.rewards?.map((e, i) => {
                                return (<React.Fragment key={i}>
                                    <div className='flexRow'>
                                        <h3>{e?.level}</h3>
                                        <h3>{e?.payOutAmount}</h3>
                                        <h3>{e?.revenueAmount}</h3>
                                        <h3>{e?.minutesPlayed}</h3>
                                        <h3>
                                            <Edit className='editReward' onClick={() => handlerewardObject(e, i)} />
                                        </h3>
                                        <h3>
                                            <Delete onClick={() => popReward(e)} />
                                        </h3>

                                    </div>
                                </React.Fragment>)
                            })
                        }
                    </div>

                    <div className='offerCountryFilter'>
                        <div className='editCover responsiveSelect'>
                            <CustomizedHook onSelectedValuesChange={handleSelectedValuesChange} />
                        </div>
                        <div className='editCover editBio'>
                            <p>
                                Selected Countries
                            </p>
                            <div>
                                {formData.countries.map((e, key) => {
                                    return <span key={key}>{e.country} , </span>
                                })}
                            </div>
                        </div>
                    </div>

                    <div className='offerImageFlex'>
                        <div className='editCover editBio'>
                            <p>
                                Offer cover Image
                            </p>
                            <div className="pRelative">
                                <label htmlFor="inputTagField">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="inputTagField"
                                        key={fileInputKey}
                                        accept="image/png , image/jpg , image/jpeg , image/webp"
                                        name="photo"
                                        multiple
                                        onChange={(e) => handlefileInput(e, 1)}
                                        className="filetag"
                                    />
                                </label>
                            </div>
                            <div>
                                <img src={formData?.image?.coverImage} alt="voucherimgae" className='voucher_image offerimage' />
                            </div>
                        </div>
                        <div className='editCover editBio'>
                            <p>
                                Offer Image
                            </p>
                            <div className="pRelative">
                                <label htmlFor="inputTag">
                                    <Picadd className="addImgae" />
                                    <input
                                        type="file"
                                        id="inputTag"
                                        key={fileInputKey}
                                        name="photo"
                                        multiple
                                        onChange={(e) => handlefileInput(e, 2)}
                                        className="filetag"
                                    />
                                </label>
                            </div>
                            <div>
                                <img src={formData?.image?.base} alt="voucherimgae" className='voucher_image offerimage' />
                            </div>
                        </div>
                    </div>


                    <div className='editCover editBio'>
                        <p>
                            Descriptions
                        </p>
                        <div className="pRelative">
                            <JoditEditor
                                value={formData?.description}
                                onChange={(newContent) =>
                                    setFormData({ ...formData, description: newContent })
                                }
                            />
                        </div>
                    </div>

                    <div className='editCover editBio'>
                        <p>
                            Important Tips
                        </p>
                        <div className="pRelative">
                            <JoditEditor
                                value={formData?.important_tips}
                                onChange={(newContent) =>
                                    setFormData({ ...formData, important_tips: newContent })
                                }
                            />
                        </div>
                    </div>


                    <div className='editCover editBio'>
                        <p>
                            Conditions
                        </p>
                        <div className="pRelative">
                            <JoditEditor
                                value={formData?.conditions}
                                onChange={(newContent) =>
                                    setFormData({ ...formData, conditions: newContent })
                                }
                            />
                        </div>
                    </div>


                    <div className='editCover editBio'>
                        <p>
                            Small Descriptions
                        </p>
                        <div className="pRelative">
                            <JoditEditor
                                value={formData?.small_descriptions}
                                onChange={(newContent) =>
                                    setFormData({ ...formData, small_descriptions: newContent })
                                }
                            />
                        </div>
                    </div>
                </div>
                <div className='editButton' onClick={() => sendData()}>Save</div>
            </div>

            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox'>
                    <h3 className='addRew'> Edit Reward </h3>

                    <div className='editCover fullCover'>
                        <p>
                            Level
                        </p>
                        <input
                            name="level"
                            placeholder={'level'}
                            onChange={(e) => {
                                setRewardObject({ ...RewardObject, level: e.target.value });
                            }}
                            type="text"
                            value={RewardObject?.level}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover'>
                        <p>
                            Payout
                        </p>
                        <input
                            name="payout"
                            placeholder={'Payout'}
                            onChange={(e) => {

                                setRewardObject({ ...RewardObject, payOutAmount: e.target.value });

                            }}
                            type="number"
                            value={RewardObject.payOutAmount}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover'>
                        <p>
                            Revenue
                        </p>

                        <input
                            name="revenue"
                            placeholder={'Revenue'}
                            onChange={(e) => {
                                setRewardObject({ ...RewardObject, revenueAmount: e.target.value });
                            }}
                            type="number"

                            value={RewardObject.revenueAmount}

                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover'>
                        <p>
                            Minute Play
                        </p>
                        <input
                            name="minute"
                            placeholder={'Minute '}
                            onChange={(e) => {
                                setRewardObject({ ...RewardObject, minutesPlayed: e.target.value });
                            }}
                            type="number"
                            value={RewardObject.minutesPlayed}
                            className="inputTag"
                        />
                    </div>

                    <div className='addButton' onClick={() => editReward(RewardIndex)}>Edit</div>

                </div>
            </Dialog>
        </>
    )
}

export default EditOffer