
import React, { useState } from 'react'
import SideBar from './SideBar'
import axiosClient from '../axios';
import { ReactComponent as Add } from "../assets/images/add.svg"
import { ReactComponent as Delete } from "../assets/images/delete.svg"
import JoditEditor from "jodit-react";
import { ReplyTwoTone, Widgets } from '@mui/icons-material';
import { ReactComponent as Picadd } from "../assets/images/newAddPic.svg";
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import { ReactComponent as Edit } from "../assets/images/edit.svg";
import { useNavigate } from 'react-router-dom';
import { stepButtonClasses } from '@mui/material';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const Challenge = () => {
    const [open, setOpen] = useState(false)
    const options = [0, 10, 20, 30, 40, 50, 60, 70, 80, 90, 100];
    const [openPartnerDialog, setOpenPartnerDialog] = useState(false)
    const [openReferDialog, setOpenReferDialog] = useState(false)
    const [taskOpen, setTaskOpen] = useState(false)
    const navigate = useNavigate()
    const [challengeInfo, setChallengeInfo] = useState({
        name: "",
        description: "",
        duration: 0,
        payableCoins: 0,
        data: {}
    })
    const [message, setMessage] = useState("")
    const [offerDetail, setOfferDetail] = useState([])
    const [offers, setOffers] = useState(
        {
            offerImageLink: "",
            offerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        }
    )

    const [partnerOfferDetail, setPartnerOfferDetail] = useState([])
    const [partners, setPartners] = useState({
        partnerImageLink: "",
        partnerId: "",
        minimumValue: 0,
        maximumValue: 0,
        offerQuantity: 0,
        earningCoins: 0,
        winningPercentage: 0,
        winningCoin: 0
    })

    const [otherOfferDetail, setOtherOfferDetail] = useState([])
    const [others, setOthers] = useState({
        referImageLink: "",
        numberOfRefer: 0,
        minimumReferEarning: 0,
        maximumReferEarning: 0,
        winningPercentage: 0,
        winningCoin: 0

    })

    const [task, setTask] = useState({
        imageLink: "",
        trackLink: ""
    })
    const [taskEditable, setTaskEditable] = useState(false)
    const [taskIndex, setTaskIndex] = useState(0)

    const handleImage = async (e, type) => {
        const photo = new FormData();
        const selectedFile = e.target.files[0];
        const file = e.target.files[0];

        if (file) {
            const img = new Image();
            img.src = URL.createObjectURL(file);
            photo.append("photo", selectedFile);

            await axiosClient
                .post(`/uploadImage`, photo)
                .then((res) => {
                    if (type == 1) {
                        setOffers({ ...offers, offerImageLink: res.data.response.url })
                    }
                    if (type == 2) {
                        setPartners({ ...partners, partnerImageLink: res.data.response.url })
                    }
                    if (type == 3) {
                        setOthers({ ...others, referImageLink: res.data.response.url })
                    }
                })
                .catch((err) => console.log(err));
        }
    }

    const handleClose = () => {
        setOpen(false)
        setTaskIndex(0)
        setTaskEditable(false)
        setTask({ imageLink: "", trackLink: "" });
        setTaskOpen(false)
        setOpenReferDialog(false)
        setOpenPartnerDialog(false)
        setOffers({
            offerImageLink: "",
            offerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0

        }) // Formerly setOfferDetail
        setPartners({
            partnerImageLink: "",
            partnerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0,
        }) // Formerly setPartnerOfferDetail
        setOthers({
            referImageLink: "",
            numberOfRefer: 0,
            minimumReferEarning: 0,
            maximumReferEarning: 0,
            winningPercentage: 0,
            winningCoin: 0
        }) // Formerly setReferOfferDetail
    }

    const handleAddOfferDetail = () => {
        if (!offers?.offerImageLink) {
            alert("Missing image")
            return
        }

        if (offers?.minimumValue || offers?.maximumValue) {
            if (!offers?.offerQuantity) {
                alert("Please enter the offer quantity of this criteria.")
                return
            }
            if (offers?.earningCoins) {
                alert("You can only create the challange on the basis of min coin value offer or max coin offer value")
                return
            }
            if (offers?.offerId) {
                alert('You can not mention the offer Id in case of sorting the offer on the basis of offer coin value')
                return
            }
        }
        if (offers?.earningCoins) {
            if (offers?.minimumValue || offers?.maximumValue) {
                alert("You can only create the challange on the basis earning coins.")
                return
            }
            if (offers?.offerId) {
                alert("You can not mention the offer Id in case of sorting the offer on the basis of earning coin")
                return
            }
        }

        if (!offers?.minimumValue && !offers?.maximumValue && !offers?.earningCoins && !offers?.offerId) {
            alert("Please mention the field value on the basis of which you want to create the offer,field are min,max coin value ,earning coin and offerid.")
            return
        }


        if (offers?.minimumValue < 0 || offers?.maximumValue < 0 || offers?.earningCoins < 0 || offers?.offerQuantity < 0 || offers?.winningPercentage < 0 || offers?.winningCoin < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (!offers?.winningPercentage && !offers?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (offers?.minimumValue > offers?.maximumValue) {
            alert("Min value can not be greater than max value...")
            return
        }


        if (!offers?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(offers?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const updatedOfferDetail = [...offerDetail, offers];
        setOfferDetail(updatedOfferDetail);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                offerDetail: updatedOfferDetail
            }
        }));
        setOpen(false);
        setOffers({
            offerImageLink: "",
            offerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        })
    };

    const handlePartnerAdd = () => {
        if (!partners?.partnerImageLink) {
            alert("Missing image")
            return
        }
        if (!partners?.partnerId) {
            alert("Missing parnerId : partnerId is mandatory.")
            return
        }

        if (
            partners?.minimumValue ||
            partners?.maximumValue
        ) {
            if (!partners?.offerQuantity) {
                alert("Please enter the offer quantity of this criteria.")
                return
            }
            if (partners?.earningCoins) {
                alert("You can only create the challange on the basis of min coin value offer or max coin offer value.")
                return
            }
        }
        if (partners?.earningCoins) {
            if (
                partners?.minimumValue ||
                partners?.maximumValue
            ) {
                alert("You can only create the challange on the basis earning coins.")
                return
            }
        }


        if (!partners?.winningPercentage && !partners?.winningCoin) {
            alert("Please enter the winning percentage or winning percentage of this criteria.")
            return
        }

        if (partners?.minimumValue < 0 || partners?.maximumValue < 0 || partners?.earningCoins < 0 || partners?.offerQuantity < 0 || partners?.winningCoin < 0 || partners?.winningPercentage < 0) {
            alert("You Can not give negative field  value...")
            return
        }

        if (partners?.minimumValue > partners?.maximumValue) {
            alert("Min value can not be greater than max value...")
            return
        }

        if (!partners?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(partners?.winningPercentage)) {
                alert("Winning Coin value should be onlye 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const updatedPartnerOfferDetail = [...partnerOfferDetail, partners];
        setPartnerOfferDetail(updatedPartnerOfferDetail);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                partnerOfferDetail: updatedPartnerOfferDetail
            }
        }));
        setOpenPartnerDialog(false);
        setPartners({
            partnerImageLink: "",
            partnerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        })
    }

    const handleReferOfferDetails = () => {
        if (!others?.referImageLink) {
            alert("Missing image")
            return
        }
        if (!others?.numberOfRefer) {
            alert("Missing numberOfRefer")
            return
        }
        if (!others?.minimumReferEarning) {
            alert('Missing minimumReferEarning')
            return
        }
        if (!others?.maximumReferEarning) {
            alert("Missing maximumReferEarning")
            return
        }

        if (others?.numberOfRefer < 0 || others?.minimumReferEarning < 0 || others?.maximumReferEarning < 0 || others?.winningCoin < 0 || others?.winningPercentage < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (others?.minimumReferEarning > others?.maximumReferEarning) {
            alert("minimumReferEarning value can not be greater than minimumReferEarning value...")
            return
        }


        if (!others?.winningPercentage && !others?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!others?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(others?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const updatedReferOfferDetail = [...otherOfferDetail, others];
        setOtherOfferDetail(updatedReferOfferDetail);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                otherOfferDetail: [{ referOffer: updatedReferOfferDetail }]
            }
        }));
        setOpenReferDialog(false);
        setOthers({
            referImageLink: "",
            numberOfRefer: 0,
            minimumReferEarning: 0,
            maximumReferEarning: 0,
            winningPercentage: 0,
            winningCoin: 0
        })
    }

    // const handleOfferDelete = (index) => {
    //     const offerDetailToUpdate = [...offerDetail];
    //     offerDetailToUpdate.splice(index, 1);
    //     setOfferDetail(offerDetailToUpdate);
    //     setChallengeInfo(prevState => ({
    //         ...prevState,
    //         data: {
    //             ...prevState.data,
    //             offerDetail: offerDetailToUpdate
    //         }
    //     }));
    // }

    const handleOfferDelete = (index) => {
        const offerDetailToUpdate = [...offerDetail];
        offerDetailToUpdate.splice(index, 1);

        setOfferDetail(offerDetailToUpdate);

        if (offerDetailToUpdate.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    offerDetail: offerDetailToUpdate
                }
            }));
        } else {
            setChallengeInfo(prevState => {
                // Create a new data object without the offerDetail key
                const { offerDetail, ...restData } = prevState.data;
                return {
                    ...prevState,
                    data: restData
                };
            });
        }
    };


    const handlePartnerDelete = (index) => {
        const partnerOfferDetailToUpdate = [...partnerOfferDetail];
        partnerOfferDetailToUpdate.splice(index, 1);
        setPartnerOfferDetail(partnerOfferDetailToUpdate);
        if (partnerOfferDetailToUpdate?.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    partnerOfferDetail: partnerOfferDetailToUpdate
                }
            }));
        } else {
            setChallengeInfo(preState => {
                const { partnerOfferDetail, ...restData } = preState.data
                return {
                    ...preState,
                    data: restData
                }
            })
        }
    }

    const handleReferDelete = (index) => {
        const referOfferDetailToUpdate = [...otherOfferDetail];
        referOfferDetailToUpdate.splice(index, 1);
        setOtherOfferDetail(referOfferDetailToUpdate);
        if (referOfferDetailToUpdate?.length > 0) {
            setChallengeInfo(prevState => ({
                ...prevState,
                data: {
                    ...prevState.data,
                    otherOfferDetail: [{ referOffer: referOfferDetailToUpdate }]
                }
            }));
        }
        else {
            setChallengeInfo(preState => {
                const { otherOfferDetail, ...restData } = preState.data
                return {
                    ...preState,
                    data: restData
                }
            })

        }

    }

    const handleOfferEdit = (index, newTask) => {

        if (!newTask?.offerImageLink) {
            alert("Missing image")
            return
        }
        if (newTask?.minimumValue || newTask?.maximumValue) {
            if (!newTask?.offerQuantity) {
                alert("Please enter the offer quantity of this criteria.")
                return
            }
            if (newTask?.earningCoins) {
                alert("You can only create the challange on the basis of min coin value offer or max coin offer value")
                return
            }
            if (newTask?.offerId) {
                alert('You can not mention the offer Id in case of sorting the offer on the basis of offer coin value')
                return
            }
        }
        if (newTask?.earningCoins) {
            if (newTask?.minimumValue || newTask?.maximumValue) {
                alert("You can only create the challange on the basis earning coins.")
                return
            }
            if (newTask?.offerId) {
                alert("You can not mention the offer Id in case of sorting the offer on the basis of earning coin")
                return
            }
        }


        if (!newTask?.minimumValue && !newTask?.maximumValue && !newTask?.earningCoins && !newTask?.offerId) {
            alert("Please mention the field value on the basis of which you want to create the offer,field are min,max coin value ,earning coin and offerid.")
            return
        }

        if (newTask?.minimumValue < 0 || newTask?.maximumValue < 0 || newTask?.earningCoins < 0 || newTask?.offerQuantity < 0 || newTask?.winningPercentage < 0 || newTask?.winningCoin < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (newTask?.minimumValue > newTask?.maximumValue) {
            alert("Min value can not be greater than max value...")
            return
        }

        if (!newTask?.winningPercentage && !newTask?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!newTask?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(newTask?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const offerDetailToUpdate = [...offerDetail];
        offerDetailToUpdate[index] = newTask;
        setOfferDetail(offerDetailToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                offerDetail: offerDetailToUpdate
            }
        }));

        setOpen(false);
        setTaskIndex(0);
        setTaskEditable(false);
        setOffers({ // Renamed from 
            offerImageLink: "",
            offerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        });
    };

    const handlePartnerEdit = (index, newTask) => {
        if (!newTask?.partnerImageLink) {
            alert("Missing image")
            return
        }
        if (!newTask?.partnerId) {
            alert("Missing parnerId : partnerId is mandatory.")
            return
        }

        if (
            newTask?.minimumValue ||
            newTask?.maximumValue
        ) {
            if (!newTask?.offerQuantity) {
                alert("Please enter the offer quantity of this criteria.")
                return
            }
            if (newTask?.earningCoins) {
                alert("You can only create the challange on the basis of min coin value offer or max coin offer value.")
                return
            }
        }
        if (newTask?.earningCoins) {
            if (
                newTask?.minimumValue ||
                newTask?.maximumValue
            ) {
                alert("You can only create the challange on the basis earning coins.")
                return
            }
        }

        if (newTask?.minimumValue > newTask?.maximumValue) {
            alert("Min value can not be greater than max value...")
            return
        }

        if (!newTask?.minimumValue && !newTask?.maximumValue && !newTask?.earningCoins && !newTask?.partnerId) {
            alert("Please mention the field value on the basis of which you want to create the offer,field are min,max coin value ,earning coin and offerid.")
            return
        }

        if (newTask?.minimumValue < 0 || newTask?.maximumValue < 0 || newTask?.earningCoins < 0 || newTask?.offerQuantity < 0 || newTask?.winningPercentage < 0 || newTask?.winningCoin < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (!newTask?.winningPercentage && !newTask?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!newTask?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(newTask?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const partnerOfferDetailToUpdate = [...partnerOfferDetail];
        partnerOfferDetailToUpdate[index] = newTask;
        setPartnerOfferDetail(partnerOfferDetailToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                partnerOfferDetail: partnerOfferDetailToUpdate
            }
        }));

        setOpenPartnerDialog(false);
        setPartners({
            partnerImageLink: "",
            partnerId: "",
            minimumValue: 0,
            maximumValue: 0,
            offerQuantity: 0,
            earningCoins: 0,
            winningPercentage: 0,
            winningCoin: 0
        });
        setTaskIndex(0);
        setTaskEditable(false);
    };

    const handleReferEdit = (index, newTask) => {

        if (!newTask?.referImageLink) {
            alert("Missing image")
            return
        }
        if (!newTask?.numberOfRefer) {
            alert("Missing numberOfRefer")
            return
        }
        if (!newTask?.minimumReferEarning) {
            alert('Missing minimumReferEarning')
            return
        }
        if (!newTask?.maximumReferEarning) {
            alert("Missing maximumReferEarning")
            return
        }

        if (newTask?.numberOfRefer < 0 || newTask?.minimumReferEarning < 0 || newTask?.maximumReferEarning < 0 || newTask?.winningPercentage < 0 || newTask?.winningCoin < 0) {
            alert("You Can not give negative field value...")
            return
        }

        if (newTask?.minimumReferEarning > newTask?.maximumReferEarning) {
            alert("minimumReferEarning value can not be greater than minimumReferEarning value...")
            return
        }

        if (!newTask?.winningPercentage && !newTask?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!newTask?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(newTask?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        if (!newTask?.winningPercentage && !newTask?.winningCoin) {
            alert("Please enter the winning percentage or winning coin of this criteria.")
            return
        }

        if (!newTask?.winningCoin) {
            let value = [10, 20, 30, 40, 50, 60, 70, 80, 90, 100]
            if (!value.includes(newTask?.winningPercentage)) {
                alert("Winning Coin value should be only 10, 20, 30, 40, 50, 60, 70, 80, 90, 100")
                return
            }
        }

        const referOfferDetailToUpdate = [...otherOfferDetail];
        referOfferDetailToUpdate[index] = newTask;
        setOtherOfferDetail(referOfferDetailToUpdate);
        setChallengeInfo(prevState => ({
            ...prevState,
            data: {
                ...prevState.data,
                // otherOfferDetail: referOfferDetailToUpdate 
                otherOfferDetail: [{ referOffer: referOfferDetailToUpdate }]
            }
        }));

        setOpenReferDialog(false);
        setOthers({
            referImageLink: "",
            numberOfRefer: 0,
            minimumReferEarning: 0,
            maximumReferEarning: 0,
            winningPercentage: 0,
            winningCoin: 0

        });
        setTaskIndex(0);
        setTaskEditable(false);
    };

    const handleCreateChallenge = async () => {
        if (!challengeInfo?.name || !challengeInfo?.description || !challengeInfo?.duration || !challengeInfo?.payableCoins) {
            alert("Please Enter All Fields Value Properly");
            return;
        }

        if (challengeInfo?.duration <= 0 || challengeInfo?.payableCoins <= 0) {
            alert("Time Duration and PayableCoins can not be 0 or less than 0");
            return;
        }


        await axiosClient
            .post("/add/task", challengeInfo)
            .then((res) => {
                setMessage(res?.data?.message)
                if (res?.data?.status === false) {
                    alert(message)
                    return
                }
                setTaskOpen(true);
            })
            .catch((err) => console.log(err));
    }

    return (
        <>
            <SideBar handleSideBar={true} activeTab={"challenges"} />
            <div className="screen">
                <div className="challenge-container">
                    <h2>Create Challenge</h2>
                    <div className="challenge-field">
                        <span className='challenge-heading'>Name</span>
                        <input
                            className='challenge-input'
                            type="text"
                            name='name'
                            onChange={(e) => {
                                setChallengeInfo({ ...challengeInfo, name: e.target.value })
                            }}
                            placeholder='Enter Name'
                            value={challengeInfo.name}
                        />
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>Description</span>
                        {/* <input className='challenge-input' type="text" /> */}

                        <div className="challenge-input JoditEditor">
                            <JoditEditor
                                value={challengeInfo.description}
                                onChange={(newContent) =>
                                    setChallengeInfo({ ...challengeInfo, description: newContent })
                                }
                            />
                        </div>
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>Time Duration <span className='in-minutes'>(in min)</span> </span>
                        <input
                            className='challenge-input'
                            type="Number"
                            name="duration"
                            placeholder='Enter Time Duration'
                            value={challengeInfo.duration}
                            onChange={(e) => {
                                setChallengeInfo({ ...challengeInfo, duration: Number(e.target.value) })
                            }}
                        />
                    </div>

                    <div className="challenge-field">
                        <span className='challenge-heading'>PayableCoins</span>
                        <input
                            className='challenge-input'
                            type="Number"
                            name='payablecoins'
                            value={challengeInfo.payableCoins}
                            placeholder='Enter Placeholder'
                            onChange={(e) => { setChallengeInfo({ ...challengeInfo, payableCoins: Number(e.target.value) }) }}
                        />
                    </div>

                    <div className="challenge-field">
                        <p className='challenge-heading'>Add Offer <Add className='addReward' onClick={() => { setOpen(true) }} /></p>

                        {(offerDetail.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>offer image</th>
                                    <th>offerId</th>
                                    <th>minimum Value</th>
                                    <th>maximum Value</th>
                                    <th>offer Quantity</th>
                                    <th>earning Coins</th>
                                    <th>winning Coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>

                                    {offerDetail.map((item, i) => {
                                        return (
                                            <tr key={i} >
                                                <td>
                                                    <img src={item?.offerImageLink} alt="" width="100%" height="40px" />
                                                </td>
                                                <td>
                                                    {item?.offerId}
                                                </td>
                                                <td>{item?.minimumValue}</td>
                                                <td>{item?.maximumValue}</td>
                                                <td>{item?.offerQuantity}</td>
                                                <td>{item?.earningCoins}</td>
                                                <td>{item?.winningPercentage ? `${item?.winningPercentage} %` : `${item?.winningCoin} coin`}</td>
                                                <td><Edit
                                                    onClick={() => {
                                                        setOpen(true);
                                                        setOffers(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete onClick={() => {
                                                    // setTaskIndex(i)
                                                    // setOpenDeleteDialog(true)
                                                    handleOfferDelete(i)
                                                }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>


                        </div>}
                    </div>

                    <div className="challenge-field">
                        <p className='challenge-heading'>Add Partner <Add className='addReward' onClick={() => { setOpenPartnerDialog(true) }} /></p>

                        {(partnerOfferDetail.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>Partner Image</th>
                                    <th>PartnerId</th>
                                    <th>minimum Value</th>
                                    <th>maximum Value</th>
                                    <th>offer Quantity</th>
                                    <th>earning Coins</th>
                                    <th>winning coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>

                                    {partnerOfferDetail.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <img src={item?.partnerImageLink} alt="" width="100%" height="40px" />
                                                </td>
                                                <td>
                                                    {item?.partnerId}
                                                </td>
                                                <td>{item?.minimumValue}</td>
                                                <td>{item?.maximumValue}</td>
                                                <td>{item?.offerQuantity}</td>
                                                <td>{item?.earningCoins}</td>
                                                <td>{item?.winningPercentage ? `${item?.winningPercentage} %` : `${item?.winningCoin} coin`}</td>
                                                <td><Edit
                                                    onClick={() => {
                                                        setOpenPartnerDialog(true)
                                                        setPartners(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete onClick={() => {
                                                    // setTaskIndex(i)
                                                    // handlePartnerDelete(i)
                                                    handlePartnerDelete(i)
                                                }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>

                    <div className="challenge-field">
                        <p className='challenge-heading'>Add Refer Details <Add className='addReward' onClick={() => { setOpenReferDialog(true) }} /></p>

                        {(otherOfferDetail.length > 0) && <div className='challenge-preview'>
                            <table className='challenge-task-tabel'>
                                <thead>
                                    <th>Refer Image</th>
                                    <th>number Of Refer</th>
                                    <th>minRefer Earning</th>
                                    <th>maxRefer Earning</th>
                                    <th>winning Coin</th>
                                    <th>Edit</th>
                                    <th>Delete</th>
                                </thead>
                                <tbody>
                                    {otherOfferDetail.map((item, i) => {
                                        return (
                                            <tr key={i}>
                                                <td>
                                                    <img src={item?.referImageLink} alt="" width="100%" height="40px" />
                                                </td>
                                                <td>
                                                    {item?.numberOfRefer}
                                                </td>
                                                <td>{item?.minimumReferEarning}</td>
                                                <td>{item?.maximumReferEarning}</td>
                                                <td>{item?.winningPercentage ? `${item?.winningPercentage} %` : `${item?.winningCoin} coin`}</td>
                                                <td><Edit
                                                    onClick={() => {
                                                        setOpenReferDialog(true)
                                                        setOthers(item)
                                                        setTaskIndex(i)
                                                        setTaskEditable(true)
                                                    }}
                                                /></td>
                                                <td><Delete onClick={() => {
                                                    // setTaskIndex(i)
                                                    // handleReferDelete(i)
                                                    handleReferDelete(i)
                                                }} /></td>
                                            </tr>
                                        )
                                    })}
                                </tbody>
                            </table>
                        </div>}
                    </div>

                    <button
                        className='challenge-button'
                        onClick={() => { handleCreateChallenge() }}
                    >
                        Submit
                    </button>
                </div>
            </div>


            <Dialog
                open={open}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox challenge-offer-dialog'>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            offer Image
                        </p>

                        <div className="pRelative">
                            <label htmlFor="offerimage">
                                <Picadd className="addImgae" />
                                <input
                                    type="file"
                                    id="offerimage"
                                    // key={fileInputKey}
                                    name="photo"
                                    multiple
                                    onChange={(e) => handleImage(e, 1)}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        {offers?.offerImageLink && <img src={offers?.offerImageLink} alt="" className="banner-image" />}
                    </div>


                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Offer Id
                        </p>
                        <input
                            name="offerid"
                            placeholder={'Enter OfferId'}
                            disabled={offers?.minimumValue || offers?.maximumValue || offers.earningCoins ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, offerId: e.target.value });
                            }}
                            type="text"
                            value={offers?.offerId}
                            className="inputTag"
                        />
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Min Coin Value
                        </p>
                        <input
                            name="min-coin"
                            placeholder={'Enter Min Coin'}
                            disabled={offers?.offerId || offers?.earningCoins ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, minimumValue: Number(e.target.value) });
                            }}
                            type="number"
                            value={offers?.minimumValue}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Max Coin Value
                        </p>
                        <input
                            name="max-coin"
                            placeholder={'Enter Max Coin'}
                            disabled={offers?.offerId || offers?.earningCoins ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, maximumValue: Number(e.target.value) });
                            }}
                            type="number"
                            value={offers?.maximumValue}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Offer Quantity
                        </p>
                        <input
                            name="offerquentity"
                            placeholder={'Enter Offer Quantity'}
                            disabled={offers?.offerId || offers?.earningCoins ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, offerQuantity: Number(e.target.value) });
                            }}
                            type="number"
                            value={offers?.offerQuantity}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Earning Coins
                        </p>
                        <input
                            name="earning"
                            placeholder={'Enter Earning Coins'}
                            disabled={offers?.minimumValue || offers?.maximumValue || offers.offerId ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, earningCoins: Number(e.target.value) });

                            }}
                            type="number"
                            value={offers?.earningCoins}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin<span className='in-minutes'>(in percent)</span>
                        </p>
                        {/* <input
                            name="winning"
                            placeholder={'Enter Winning Percentage'}
                            disabled={offers.winningCoin ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, winningPercentage: Number(e.target.value) });
                            }}
                            type="number"
                            value={offers?.winningPercentage}
                            className="inputTag"
                        /> */}

                        <select
                            id="numberSelect"
                            className='input-tag-selectbox'
                            value={offers?.winningPercentage}
                            disabled={offers.winningCoin ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, winningPercentage: Number(e.target.value) });
                            }}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        {/* <p>Selected Value: {offers?.winningPercentage}</p> */}
                    </div>


                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin
                        </p>
                        <input
                            name="winningCoin"
                            placeholder={'Enter Winning coin'}
                            disabled={offers.winningPercentage ? true : false}
                            onChange={(e) => {
                                setOffers({ ...offers, winningCoin: Number(e.target.value) });
                            }}
                            type="number"
                            value={offers?.winningCoin}
                            className="inputTag"
                        />
                    </div>

                    <div className='addButton' onClick={() => taskEditable ? handleOfferEdit(taskIndex, offers) : handleAddOfferDetail()}>{taskEditable ? "Edit" : "Add Offer"}</div>
                </div>
            </Dialog>

            <Dialog
                open={openPartnerDialog}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox challenge-offer-dialog'>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Partner Image
                        </p>

                        <div className="pRelative">
                            <label htmlFor="partnerimage">
                                <Picadd className="addImgae" />
                                <input
                                    type="file"
                                    id="partnerimage"
                                    // key={fileInputKey}
                                    name="photo"
                                    multiple
                                    onChange={(e) => handleImage(e, 2)}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        {partners?.partnerImageLink && <img src={partners?.partnerImageLink} alt="" className="banner-image" />}
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Partner Id
                        </p>
                        <input
                            name="partnerid"
                            placeholder={'Enter partnerId'}
                            onChange={(e) => {
                                setPartners({ ...partners, partnerId: e.target.value });
                            }}
                            type="text"
                            value={partners?.partnerId}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Min Coin Value
                        </p>
                        <input
                            name="min-coin"
                            placeholder={'Enter Min Coin'}
                            disabled={partners?.earningCoins ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, minimumValue: Number(e.target.value) });
                            }}
                            type="number"
                            value={partners?.minimumValue}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Max Coin Value
                        </p>
                        <input
                            name="max-coin"
                            placeholder={'Enter Max Coin'}
                            disabled={partners?.earningCoins ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, maximumValue: Number(e.target.value) });
                            }}
                            type="number"
                            value={partners?.maximumValue}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Offer Quantity
                        </p>
                        <input
                            name="offerquentity"
                            placeholder={'Enter Offer Quantity'}
                            disabled={partners?.earningCoins ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, offerQuantity: Number(e.target.value) });
                            }}
                            type="number"
                            value={partners?.offerQuantity}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Earning Coins
                        </p>
                        <input
                            name="earning"
                            placeholder={'Enter Earning Coins'}
                            disabled={partners?.minimumValue || partners?.maximumValue || partners?.offerQuantity ? true : false}

                            onChange={(e) => {
                                setPartners({ ...partners, earningCoins: Number(e.target.value) });
                            }}
                            type="number"
                            value={partners?.earningCoins}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin<span className='in-minutes'>(in percent)</span>
                        </p>
                        {/* <input
                            name="winning"
                            placeholder={'Enter Winning Percentage'}
                            disabled ={partners?.winningCoin ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, winningPercentage: Number(e.target.value) });
                            }}
                            type="number"
                            value={partners?.winningPercentage}
                            className="inputTag"
                        /> */}

                        <select
                            id="numberSelect"
                            className='input-tag-selectbox'
                            value={partners?.winningPercentage}
                            disabled={partners?.winningCoin ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, winningPercentage: Number(e.target.value) });
                            }}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        {/* <p>Selected Value: {partners?.winningPercentage}</p> */}
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin
                        </p>
                        <input
                            name="winningCoin"
                            placeholder={'Enter Winning Percentage'}
                            disabled={partners?.winningPercentage ? true : false}
                            onChange={(e) => {
                                setPartners({ ...partners, winningCoin: Number(e.target.value) });
                            }}
                            type="number"
                            value={partners?.winningCoin}
                            className="inputTag"
                        />
                    </div>
                    <div className='addButton' onClick={() => taskEditable ? handlePartnerEdit(taskIndex, partners) : handlePartnerAdd()}>{taskEditable ? "Edit" : "Add Partner"}</div>
                    {/* <div className='addButton' onClick={() => { handlePartnerAdd() }}>Add Partner</div> */}
                </div>
            </Dialog>

            <Dialog
                open={openReferDialog}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className='categoryEditBox challenge-offer-dialog'>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Refer Image
                        </p>

                        <div className="pRelative">
                            <label htmlFor="referimage">
                                <Picadd className="addImgae" />
                                <input
                                    type="file"
                                    id="referimage"
                                    // key={fileInputKey}
                                    name="photo"
                                    multiple
                                    onChange={(e) => handleImage(e, 3)}
                                    className="filetag"
                                />
                            </label>
                        </div>
                        {others?.referImageLink && <img src={others?.referImageLink} alt="" className="banner-image" />}
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Number of Refer
                        </p>
                        <input
                            name="partnerid"
                            placeholder={'Enter Number Of Refer'}
                            onChange={(e) => {
                                setOthers({ ...others, numberOfRefer: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.numberOfRefer}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Min Refer Earning
                        </p>
                        <input
                            name="min-refer-earning"
                            placeholder={'Enter Refer Earning'}
                            onChange={(e) => {
                                setOthers({ ...others, minimumReferEarning: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.minimumReferEarning}
                            className="inputTag"
                        />
                    </div>
                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Max Refer Earning
                        </p>
                        <input
                            name="max-coin"
                            placeholder={'Enter Max Refer Earning'}
                            onChange={(e) => {
                                setOthers({ ...others, maximumReferEarning: Number(e.target.value) });
                            }}

                            type="number"
                            value={others?.maximumReferEarning}
                            className="inputTag"
                        />
                    </div>



                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin<span className='in-minutes'>(in percent)</span>
                        </p>
                        {/* <input
                            name="winning"
                            placeholder={'Enter Winning Percentage'}
                            disabled={others?.winningCoin ? true : false}
                            onChange={(e) => {
                                setOthers({ ...others, winningPercentage: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.winningPercentage}
                            className="inputTag"
                        /> */}


                        <select
                            id="numberSelect"
                            className='input-tag-selectbox'
                            value={others?.winningPercentage}
                            disabled={others?.winningCoin ? true : false}
                            onChange={(e) => {
                                setOthers({ ...others, winningPercentage: Number(e.target.value) });
                            }}>
                            {options.map((option) => (
                                <option key={option} value={option}>
                                    {option}
                                </option>
                            ))}
                        </select>
                        {/* <p>Selected Value: {offers?.winningPercentage}</p> */}
                    </div>

                    <div className='editCover fullCover offer-challenge'>
                        <p>
                            Winning Coin
                        </p>
                        <input
                            name="winningCoin"
                            placeholder={'Enter Winning Percentage'}
                            disabled={others?.winningPercentage ? true : false}
                            onChange={(e) => {
                                setOthers({ ...others, winningCoin: Number(e.target.value) });
                            }}
                            type="number"
                            value={others?.winningCoin}
                            className="inputTag"
                        />
                    </div>

                    <div className='addButton' onClick={() => taskEditable ? handleReferEdit(taskIndex, others) : handleReferOfferDetails()}>{taskEditable ? "Edit" : "Add"}</div>
                </div>

            </Dialog>


            <Dialog
                open={taskOpen}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="task-success-message">
                    <h2>
                        {message}
                    </h2>
                    <button className='ok-cha-button' onClick={() => {
                        setTaskOpen(false);
                        setChallengeInfo(
                            {
                                name: "",
                                description: "",
                                duration: 0,
                                payableCoins: 0,
                                challenges: []
                            }
                        )
                        navigate("/allchallenge")
                    }}>Ok</button>
                </div>

            </Dialog>


            {/* <Dialog
                open={openDeleteDialog}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="task-success-message">
                    <h2>
                        Are You Sure , You Want to Delete This offer
                    </h2>

                    <div className='flex-disable'>
                        <button className='ok-cha-button' onClick={() => {
                            // handleEnabelDisableTask()
                            handleOfferDelete(taskIndex)
                        }
                        }>Yes</button>

                        <button className='ok-cha-button' onClick={() => {
                            setOpenDeleteDialog(false);
                        }
                        }>No</button>
                    </div>

                </div>
            </Dialog> */}


        </>
    )
}

export default Challenge
