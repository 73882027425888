import React, { useState } from 'react'
import Dialog from '@mui/material/Dialog';
import Slide from '@mui/material/Slide';
import  rightSign from "../assets/images/rightSign.jpg"

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const AlertDialog = ({ msg ,alertDialogOpen ,setAlertDialogOpen ,showAlertSign ,setShowAlertSigh}) => {

    const handleClose = () => { 
        setAlertDialogOpen(false);  
        setShowAlertSigh(false)
      };

    const hanldAlert = () =>{
        setAlertDialogOpen(false);
        setShowAlertSigh(false)
    }
    
    return (
        <>
            <Dialog
                open={alertDialogOpen}
                onClose={() => handleClose()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="alert-dialog-box">
                    
                    {showAlertSign ? <div>
                        <img width="100px" height="100px" src={rightSign} alt="" />
                    </div> : <p class="dialog-alert-p">Alert</p>}
                    {msg}
                    <button className='alert-dialog-button' onClick={()=>{hanldAlert()}}>Ok</button>
                </div>
            </Dialog>
        </>
    )
}

export default AlertDialog
