import React, { useState, useEffect } from 'react'
import axiosClient from '../axios';
import SideBar from './SideBar';
import Slide from '@mui/material/Slide';
import Dialog from '@mui/material/Dialog';
const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});


const ConfigurationInfo = () => {
    const [data, setData] = useState([])
    const [open, setOpen] = useState(false)
    const [singleData, setSingleData] = useState({})
    const [adsUnit, setAdsUnit] = useState([])


    const getConfigInfo = () => {
        axiosClient
            .get('/configurationInfo')
            .then((res) => {
                console.log("res==>", res)
                setData(res?.data?.response)
            })
            .catch((error) => console.log(error))
    }


    const handleCheckboxChange = (e) => {
        let { value, checked } = e.target;
        value = Number(value);
        let updatedAdsUnit;
        if (checked) {
            updatedAdsUnit = [...adsUnit, { type: value }];
        } else {
            updatedAdsUnit = adsUnit.filter((val) => val?.type !== value);
        }
        setAdsUnit(updatedAdsUnit);
        setSingleData((prevSingleData) => ({
            ...prevSingleData,
            adUnits: updatedAdsUnit,
            maxTrials: updatedAdsUnit.length,
        }));
    };
    

    const handleConfigEdit = () => {
        setOpen(false)
        axiosClient.post("/editconfigration", singleData)
            .then((res) => {
                alert("Config Edit Successfylly")
                setOpen(false)
                getConfigInfo()

            })
            .catch((error) => console.log(error))
    }

    const adUnitTypes = {
        0: "None",
        1: "Unity Interstitial",
        2: "Liftoff Interstitial",
        3: "Mintegral Rewarded",
        4: "Mintegral Interstitial",
        5: "Unity Rewarded",
        6: "Liftoff Rewarded",
    };

    const handleDelete = () => {
        setOpen(false)
    }

    useEffect(() => {
        getConfigInfo()
    }, [])


    return (
        <>
            <SideBar handleSideBar={true} activeTab={"configuration"} />
            <div className="screen">
                <h3>Configuration Info</h3>
                <div className='profileInfoBox table'>
                    <>
                        <div style={{ width: '100%' }} className="container app-body">
                            <div className='tabel-scrol'>
                                <table id="users">
                                    <thead>
                                        <tr>
                                            <th style={{ width: '20px' }}>S.NO.</th>
                                            <th style={{ width: '60px' }}>Name</th>
                                            <th style={{ width: '50px' }}>Max Trial</th>
                                            <th style={{ width: '50px' }}>Active</th>
                                            <th style={{ width: '50px' }}>is_count</th>
                                            <th style={{ width: '200px' }}>Units</th>
                                            <th style={{ width: '50px' }}>Edit</th>
                                        </tr>
                                    </thead>
                                    {data?.map((val, key) => {
                                        return (
                                            <tbody key={key}>
                                                <tr>
                                                    <td>{(key + 1)}</td>
                                                    <td >{val?.name}</td>
                                                    <td>{val?.maxTrials}</td>
                                                    <td>{val.active ? "true" : "false"}</td>
                                                    <td>{val.is_count ? "true" : "false"}</td>
                                                    <td>
                                                        {val?.adUnits?.map((item, index) => {
                                                            return <p key={index}>{adUnitTypes[item?.type] || "Unknown Type"}</p>;
                                                        })}
                                                    </td>

                                                    <td>{<p className='TransactionBox' onClick={() => {
                                                        setOpen(true); setSingleData(val); setAdsUnit(() => {
                                                            let arr = [];
                                                            // val?.adUnits.forEach((item) => arr.push(item.type));
                                                            val?.adUnits.forEach((item) => {
                                                                arr.push(item)
                                                            });
                                                            return arr;
                                                        });
                                                    }} >Edit </p>}</td>
                                                </tr>
                                            </tbody>
                                        )
                                    })
                                    }
                                </table>
                            </div>
                        </div>
                    </>
                </div>
            </div>

            <Dialog
                open={open}
                onClose={() => handleDelete()}
                TransitionComponent={Transition}
                className="dialogs"
            >
                <div className="configuration-edit">
                    <h3>Edit Config Info</h3>
                    <div className="config-info">
                        <p>Active</p>
                        <div className='config-radio-sec'>
                            <input
                                type="radio"
                                checked={singleData?.active}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, active: true })
                                }}
                            />
                            <span>True</span>
                            <input
                                type="radio"
                                checked={!singleData?.active}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, active: false })
                                }}
                            />
                            <span>false</span>
                        </div>
                    </div>
                    <div className="config-info">
                        <p>is Count</p>
                        <div className='config-radio-sec'>
                            <input type="radio"
                                checked={singleData.is_count}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, is_count: true })
                                }}
                            />
                            <span>True</span>
                            <input type="radio"
                                checked={!singleData.is_count}
                                onChange={(e) => {
                                    setSingleData({ ...singleData, is_count: false })
                                }}
                            />
                            <span>false</span>
                        </div>
                    </div>
                    <div className="config-info config-info-flex">
                        <p className='config-unit'> Units</p>

                        <div className='config-label'>
                            <label>
                                <input
                                    type="checkbox"
                                    value="1"
                                    checked={adsUnit?.some((val) => val?.type === 1)}
                                    onChange={handleCheckboxChange}
                                /> unity Interstitial</label>
                            <label>
                                <input
                                    type="checkbox"
                                    value="2"
                                    checked={adsUnit?.some((val) => val?.type === 2)}
                                    onChange={handleCheckboxChange}
                                /> liftoff Interstitial</label>
                            <label>
                                <input
                                    type="checkbox"
                                    value="3"
                                    checked={adsUnit?.some((val) => val?.type === 3)}
                                    onChange={handleCheckboxChange}
                                /> mintegral Rewarded</label>
                            <label>
                                <input
                                    type="checkbox"
                                    value="4"
                                    checked={adsUnit?.some((val) => val?.type === 4)}
                                    onChange={handleCheckboxChange}
                                /> mintegral interstitial</label>
                            <label><input
                                type="checkbox"
                                value="5"
                                checked={adsUnit?.some((val) => val?.type === 5)}
                                onChange={handleCheckboxChange}
                            /> unity rewarded</label>
                            <label>
                                <input
                                    type="checkbox"
                                    value="6"
                                    checked={adsUnit?.some((val) => val?.type === 6)}
                                    onChange={handleCheckboxChange}
                                /> liftoff rewarded</label>

                        </div>

                    </div>
                    <button className='config-button' onClick={() => handleConfigEdit()}>Edit</button>
                </div>
            </Dialog>
        </>
    )
}

export default ConfigurationInfo
